import { createContext } from 'react';

import { IUser } from '../interfaces';
import { IWeb2Client } from '../web2/web2Client';
import { IWeb3Client } from '../web3/web3Client';
import { IWeb3Chain } from '../web3/web3Utils';
import { PaletteMode } from '@mui/material';

export interface IAppContext {
	isAuthenticated: boolean;
	chain?: IWeb3Chain;
	user?: IUser;
	setUser?: (user: IUser) => void;
	logoutUser?: () => void;
	web3Client?: IWeb3Client;
	web2Client?: IWeb2Client;
	theme?: PaletteMode;
	setTheme?: (theme: PaletteMode) => void;
}

export const AppContext = createContext<IAppContext>({
	isAuthenticated: false,
	chain: undefined,
	user: undefined,
	setUser: undefined,
	logoutUser: undefined,
	web3Client: undefined,
	web2Client: undefined,
	theme: undefined,
	setTheme: undefined,
});
