import { BigNumber, BigNumberish, ethers } from 'ethers';
import arbitrumIconWhite from '../assets/arbitrum-icon-white.svg';
import polygonIconWhite from '../assets/polygon-icon-white.svg';
import ethereumIconWhite from '../assets/ethereum-icon-white.svg';
import berachainIconWhite from '../assets/berachain-icon.svg';
import coreIconWhite from '../assets/core-icon.svg';
import baseIconWhite from '../assets/base-icon.svg';
import { TokenInfo } from '../interfaces';

export interface IWeb3Chain {
	particleName: string;
	chainId: number;
	rpcTarget: string;
	name: string;
	symbol: string;
	blockExplorerUrl: string;
	postmintTokenAddress: string;
	sigCampaignAddress: string;
	factoryAddress: string;
	kolMarketplaceAddress: string;
	isTestnet: boolean;
	usdcAddress: string;
	usdtAddress: string;
	tokenInfos: {
		[token: string]: TokenInfo;
	};
	icon: {
		icon: string;
		background: string;
	};
}

export const DEFAULT_CHAIN_ID = 137; // 42161;
export const DEFAULT_ENV_CHAIN_ID =
	process.env.NODE_ENV !== 'development' ? 42161 : 421614;

export const CHAIN_MAP: {
	[chainId: string]: IWeb3Chain;
} = {
	80001: {
		// deprecated
		particleName: 'PolygonMumbai',
		name: 'Polygon Mumbai Testnet',
		chainId: 80001,
		rpcTarget: 'https://rpc-mumbai.maticvigil.com',
		symbol: 'MATIC',
		blockExplorerUrl: 'https://mumbai.polygonscan.com/',
		postmintTokenAddress:
			process.env.REACT_APP_POSTMINT_TOKEN_ADDRESS ?? '',
		sigCampaignAddress: process.env.REACT_APP_SIG_CAMPAIGN_ADDRESS ?? '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0xa2f4E6c59BE40015DD09c75b53FeE68E2f33230F',
		usdtAddress: '0x3ce10a127db01ceebe8680ebd970a580a8558a6b',
		tokenInfos: {
			'0xDfE70d4dC3a8c586345d27bf03dbb6559f15FDe9': {
				name: 'PostmintPointToken',
				symbol: 'PPT',
				decimals: 18,
			},
			'0xa2f4E6c59BE40015DD09c75b53FeE68E2f33230F': {
				name: 'USDC Mock',
				symbol: 'USDCm',
				decimals: 6,
			},
			'0x3ce10a127db01ceebe8680ebd970a580a8558a6b': {
				name: 'USDC Mock',
				symbol: 'USDCm',
				decimals: 6,
			},
		},
		icon: {
			icon: polygonIconWhite,
			background:
				'linear-gradient(225deg, #CBE04C 0%, #38E388 48.96%, #00C8CC 100%)',
		},
	},
	80002: {
		particleName: 'PolygonAmoy',
		name: 'Polygon Amoy',
		chainId: 80002,
		rpcTarget: 'https://rpc-amoy.polygon.technology',
		symbol: 'MATIC',
		blockExplorerUrl: 'https://amoy.polygonscan.com/',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: polygonIconWhite,
			background:
				'linear-gradient(225deg, #CBE04C 0%, #38E388 48.96%, #00C8CC 100%)',
		},
	},
	137: {
		particleName: 'Polygon',
		name: 'Polygon Mainnet',
		chainId: 137,
		rpcTarget: 'https://rpc-mainnet.matic.quiknode.pro',
		symbol: 'MATIC',
		blockExplorerUrl: 'https://polygonscan.com/',
		postmintTokenAddress:
			process.env.REACT_APP_POSTMINT_TOKEN_ADDRESS ?? '',
		sigCampaignAddress: process.env.REACT_APP_SIG_CAMPAIGN_ADDRESS ?? '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
		usdtAddress: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
		tokenInfos: {
			'0x10bEd3a666a058a57A9676df81DD9F8aB3535762': {
				name: 'PostmintPointToken',
				symbol: 'PPT',
				decimals: 18,
			},
			'0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
				name: 'USD Coin (PoS)',
				symbol: 'USDC',
				decimals: 6,
			},
			'0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
				name: '(PoS) Tether USD',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: polygonIconWhite,
			background: '#8247E5',
		},
	},
	1101: {
		particleName: 'PolygonzkEVM',
		name: 'Polygon zkEVM Mainnet',
		chainId: 1101,
		rpcTarget: 'https://zkevm-rpc.com',
		symbol: 'ETH',
		blockExplorerUrl: 'https://zkevm.polygonscan.com',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5',
		usdtAddress: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
		tokenInfos: {
			'0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5': {
				name: 'USD Coin',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x1E4a5963aBFD975d8c9021ce480b42188849D41d': {
				name: 'Tether USD',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: polygonIconWhite,
			background: '#8247E5',
		},
	},
	1442: {
		// deprecated
		particleName: 'PolygonzkEVMTestnet',
		name: 'Polygon zkEVM Testnet',
		chainId: 1442,
		rpcTarget: 'https://rpc.public.zkevm-test.net',
		symbol: 'ETH',
		blockExplorerUrl: 'https://testnet-zkevm.polygonscan.com',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: 'https://static.particle.network/token-list/polygonzkevm/native.png',
			background: 'linear-gradient(225deg, #EE6FFF 0%, #CBEEFF 100%)',
		},
	},
	2442: {
		particleName: 'PolygonzkEVMCardona',
		name: 'Polygon zkEVM Cardona',
		chainId: 2442,
		rpcTarget: 'https://rpc.cardona.zkevm-rpc.com',
		symbol: 'ETH',
		blockExplorerUrl: 'https://cardona-zkevm.polygonscan.com',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: 'https://static.particle.network/token-list/polygonzkevm/native.png',
			background: 'linear-gradient(225deg, #EE6FFF 0%, #CBEEFF 100%)',
		},
	},
	42161: {
		particleName: 'ArbitrumOne',
		name: 'Arbitrum One',
		chainId: 42161,
		rpcTarget: 'https://rpc.ankr.com/arbitrum',
		symbol: 'AETH',
		blockExplorerUrl: 'https://arbiscan.io/',
		postmintTokenAddress:
			process.env.REACT_APP_POSTMINT_TOKEN_ADDRESS ?? '',
		sigCampaignAddress: process.env.REACT_APP_SIG_CAMPAIGN_ADDRESS ?? '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
		usdtAddress: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
		tokenInfos: {
			'0x10bEd3a666a058a57A9676df81DD9F8aB3535762': {
				name: 'PostmintPointToken',
				symbol: 'PPT',
				decimals: 18,
			},
			'0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8': {
				name: 'USD Coin (Arb1)',
				symbol: 'USDC',
				decimals: 6,
			},
			'0xaf88d065e77c8cC2239327C5EDb3A432268e5831': {
				name: 'USD Coin',
				symbol: 'USDC',
				decimals: 6,
			},
			'0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9': {
				name: 'Tether USD',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: arbitrumIconWhite,
			background: '#2C374B',
		},
	},
	421613: {
		// deprecated
		particleName: 'ArbitrumGoerli',
		name: 'Arbitrum Goerli Testnet',
		chainId: 421613,
		rpcTarget: 'https://endpoints.omniatech.io/v1/arbitrum/goerli/public',
		symbol: 'AETH',
		blockExplorerUrl: 'https://testnet.arbiscan.io/',
		postmintTokenAddress:
			process.env.REACT_APP_POSTMINT_TOKEN_ADDRESS ?? '',
		sigCampaignAddress: process.env.REACT_APP_SIG_CAMPAIGN_ADDRESS ?? '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress:
			process.env.REACT_APP_KOL_MARKETPLACE_ADDRESS ?? '',
		isTestnet: true,
		usdcAddress: '0xd7B19fDee1954E271951eD3a31FC439C2233561D',
		usdtAddress: '0xA189E3334030B7B219fD688749CFfA88F14c1459',
		tokenInfos: {
			'0xDfE70d4dC3a8c586345d27bf03dbb6559f15FDe9': {
				name: 'PostmintPointToken',
				symbol: 'PPT',
				decimals: 18,
			},
			'0xd7B19fDee1954E271951eD3a31FC439C2233561D': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0xA189E3334030B7B219fD688749CFfA88F14c1459': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: arbitrumIconWhite,
			background: 'linear-gradient(225deg, #FFE600 0%, #FF9839 100%)',
		},
	},
	421614: {
		particleName: 'ArbitrumSepolia',
		name: 'Arbitrum Sepolia',
		chainId: 421614,
		rpcTarget: 'https://sepolia-rollup.arbitrum.io/rpc',
		symbol: 'ETH',
		blockExplorerUrl: 'https://sepolia.arbiscan.io/',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: arbitrumIconWhite,
			background: 'linear-gradient(225deg, #09C9A9 0%, #099999 100%)',
		},
	},
	1: {
		particleName: 'Ethereum',
		name: 'Ethereum Mainnet',
		chainId: 1,
		rpcTarget: 'https://mainnet.infura.io/v3/',
		symbol: 'ETH',
		blockExplorerUrl: 'https://etherscan.io/',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0xCcDa2424ef5Ef151AAF234655209408F84Ca35E0',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
		usdtAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
		tokenInfos: {
			'0x10bEd3a666a058a57A9676df81DD9F8aB3535762': {
				name: 'PostmintPointToken',
				symbol: 'PPT',
				decimals: 18,
			},
			'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': {
				name: 'USDC Coin',
				symbol: 'USDC',
				decimals: 6,
			},
			'0xdAC17F958D2ee523a2206206994597C13D831ec7': {
				name: 'Tether USD',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: ethereumIconWhite,
			background: '#627EEA',
		},
	},
	80085: {
		particleName: 'Berachain',
		chainId: 80085,
		rpcTarget: 'https://rpc.ankr.com/berachain_testnet',
		name: 'Berachain Artio',
		symbol: 'BERA',
		blockExplorerUrl: 'https://artio.beratrail.io/',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: berachainIconWhite,
			background: '#F47226',
		},
	},
	1115: {
		particleName: 'CoreTestnet',
		chainId: 1115,
		rpcTarget: 'https://rpc.test.btcs.network',
		name: 'Core Testnet',
		symbol: 'CORE',
		blockExplorerUrl: 'https://scan.test.btcs.network/',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: coreIconWhite,
			background: '#29272E',
		},
	},
	1116: {
		particleName: 'Core',
		chainId: 1116,
		rpcTarget: 'https://rpc.coredao.org',
		name: 'Core Mainnet',
		symbol: 'CORE',
		blockExplorerUrl: 'https://scan.coredao.org',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0xa4151b2b3e269645181dccf2d426ce75fcbdeca9',
		usdtAddress: '0x9ebab27608bd64aff36f027049aecc69102a0d1e',
		tokenInfos: {
			'0xa4151b2b3e269645181dccf2d426ce75fcbdeca9': {
				name: 'USD Coin',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x9ebab27608bd64aff36f027049aecc69102a0d1e': {
				name: 'Tether USD',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: coreIconWhite,
			background: '#FF9211',
		},
	},
	8453: {
		particleName: 'Base',
		chainId: 8453,
		rpcTarget: 'https://developer-access-mainnet.base.org',
		name: 'Base Mainnet',
		symbol: 'ETH',
		blockExplorerUrl: 'https://basescan.org',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: process.env.REACT_APP_CAMPAIGN_FACTORY_ADDRESS ?? '',
		kolMarketplaceAddress: '',
		isTestnet: false,
		usdcAddress: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
		usdtAddress: '',
		tokenInfos: {
			'0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913': {
				name: 'USD Coin',
				symbol: 'USDC',
				decimals: 6,
			},
		},
		icon: {
			icon: baseIconWhite,
			background: '#0052ff',
		},
	},
	84532: {
		particleName: 'Base',
		chainId: 84532,
		rpcTarget: 'https://sepolia.base.org',
		name: 'Base Sepolia',
		symbol: 'ETH',
		blockExplorerUrl: 'https://sepolia.basescan.org',
		postmintTokenAddress: '',
		sigCampaignAddress: '',
		factoryAddress: '0x72446330Eb14C1F82bd8bcE64c39Afe914B3BEC4',
		kolMarketplaceAddress: '',
		isTestnet: true,
		usdcAddress: '0x4f356dda466788A2EafFEE4510e36979B36403c0',
		usdtAddress: '0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f',
		tokenInfos: {
			'0x4f356dda466788A2EafFEE4510e36979B36403c0': {
				name: 'USDC',
				symbol: 'USDC',
				decimals: 6,
			},
			'0x5C90962f5Ba85EBFB8F83c58a42Aa4f4d303709f': {
				name: 'USDT',
				symbol: 'USDT',
				decimals: 6,
			},
		},
		icon: {
			icon: baseIconWhite,
			background: '#0052ff',
		},
	},
};

export interface IAllocation {
	token: string;
	amount: string;
}

export interface IBrandOffer {
	id: string;
	kol: string;
	token: string;
	amount: string;
}

export interface ICampaignPayment {
	offerID: string;
	payKOL: boolean;
}

export interface IPayment {
	token: string;
	amount: string;
	fee: string;
}

export interface IMultiProof {
	merkleProof: string[];
	proofFlags: boolean[];
}

export interface IAddedFundsEvent {
	sender: string;
	token: string;
	amount: BigNumber;
}

/**
 * default value for unit is 'ether' == 10**18 (18 decimals) - same as PPT, so for most usecases you can ommit this param
 * value accepts numbers, strings, hex-formatted values, so it should cover most cases
 * returns BigNumber instead of string, s.t. it's possible to do operations with the values
 * you can easily get a string representation with toString()
 * use this for getting atomic values out of whole values (inputs) for txs or db stores
 */
export const tokens = (value: BigNumberish, unit?: BigNumberish): BigNumber => {
	return ethers.utils.parseUnits(value.toString(), unit);
};

// Inverse of tokens ^
// Use this for formatting atomic values into whole values (outputs) to display them
export const format = (value: BigNumberish, unit?: BigNumberish): string => {
	try {
		const rounded =
			Math.round(
				parseFloat(ethers.utils.formatUnits(value, unit)) * 1e4
			) / 1e4;
		return new Intl.NumberFormat().format(rounded);
	} catch {
		console.log(`Failed to format value ${value}`);
		return '0';
	}
};

export const formatAddress = (address: string): string => {
	return address.slice(0, 6) + '...' + address.slice(-4);
};

/*
 * Get the cumulative amount of a token that has been added to the campaign,
 * i.e. the sum of all AddedFunds events for a specific token.
 * */
export const getCumulativeAmountForToken = async (
	contract: ethers.Contract,
	rewardTokenAddress: string
): Promise<BigNumber> => {
	// Filter the AddedFunds events by token address
	const filter = contract.filters.AddedFunds();
	// Optional index filter for campaignCreator on the AddedFunds event,
	// however only the owner/creator can add funds, so logically is not needed,
	// we should see if it is more performant

	// Get all the AddedFunds events that match the filter
	const events = await contract.queryFilter(filter);

	// Sum up all the amounts from the events for one token -
	// we should optimize in the future to sum upp and return all funds at once
	const addedFunds = events.reduce((acc: BigNumber, event) => {
		const fund = event.args?.funds.find(
			(fund: { token: string; amount: BigNumber }) =>
				fund.token === rewardTokenAddress
		);
		return acc.add(fund?.amount ?? BigNumber.from(0));
	}, BigNumber.from(0));

	const commissionBp = await contract.commissionBp();
	const commission = calculateCommission(commissionBp, addedFunds);
	return addedFunds.sub(commission);
};

const BASE = BigNumber.from(10_000);

export const calculateCommission = (
	commissionBp: BigNumber,
	amount: BigNumber
): BigNumber => {
	return amount.mul(commissionBp).div(BASE);
};

export const markup = (
	commissionBp: BigNumber,
	amountLeft: BigNumber
): BigNumber => {
	// the calculation is done in basis points in the following way:
	// amountLeft = amount - (amount * commissionBp) / BASE
	// "amount" is the amount necessary s.t. "amountLeft" will be left
	// after the commission is taken
	if (commissionBp.gte(BASE)) {
		return BigNumber.from(0);
	}

	// amount = amountLeft * BASE / (BASE - commissionPoints)
	return amountLeft.mul(BASE).div(BASE.sub(commissionBp));
};

export const simpleMarkup = (
	commissionBp: BigNumber,
	amountLeft: BigNumber
): BigNumber => {
	if (commissionBp.gte(BASE)) {
		return BigNumber.from(0);
	}
	/// e.g. 100 tokens with 10% commission would equal 110 tokens
	return amountLeft.mul(BASE.add(commissionBp)).div(BASE);
};

export const markdown = (
	commissionBp: BigNumber,
	funds: BigNumber
): BigNumber => {
	if (commissionBp.isZero()) {
		return funds;
	}
	return funds.mul(BASE.sub(commissionBp)).div(BASE);
};
