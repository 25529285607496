import { Box, Collapse, IconButton, Stack } from '@mui/material';

import { CSSProperties, ReactNode, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Accordion = ({
	heading,
	badges,
	children,
	style,
	innerStyle,
	innerChildrenStyle,
	handleChangeState,
}: {
	heading: JSX.Element;
	badges?: JSX.Element;
	children: ReactNode;
	style?: CSSProperties;
	innerStyle?: CSSProperties;
	innerChildrenStyle?: CSSProperties;
	handleChangeState?: (isOpen: boolean) => void;
}): JSX.Element => {
	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (handleChangeState) {
			handleChangeState(open);
		}
	}), [open];
	
	return (
		<Stack
			style={{
				...styles.accordionItemPanel,
				...(style ? style : {
					boxShadow: '0px 10px 16px 0px #14253F0F',
				}),
			}}
		>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
				onClick={() => setOpen(!open)}
				style={{
					cursor: 'pointer',
					...(innerStyle
						? innerStyle
						: { padding: '20px 24px' }),
				}}
			>
				{heading}
				<Stack direction={'row'} spacing={2}>
					{badges}
					<IconButton>
						{open ? (
							<ExpandLessIcon
								fontSize="medium"
								htmlColor="#C7CAD0"
							/>
						) : (
							<ExpandMoreIcon
								fontSize="medium"
								htmlColor="#C7CAD0"
							/>
						)}
					</IconButton>
				</Stack>
			</Stack>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Box
					style={{
						...(innerChildrenStyle
							? innerChildrenStyle
							: { padding: '0px 24px 20px 24px' }),
					}}
				>
					{children}
				</Box>
			</Collapse>
		</Stack>
	);
};

export default Accordion;

const styles = {
	accordionItemPanel: {
		backgroundColor: '#ffffff',
		borderRadius: '12px',
	} as CSSProperties,
};
