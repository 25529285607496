import { Alert, Snackbar } from '@mui/material';
import { IToastType } from '../contexts/ToastContext';

interface IToastProps {
	isOpen: boolean;
	type: IToastType;
	message: string;
	handleClose: () => void;
}

const Toast = ({
	isOpen,
	type,
	message,
	handleClose,
}: IToastProps): JSX.Element => {
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={isOpen}
			autoHideDuration={3000}
			onClose={handleClose}
			onClick={handleClose}
		>
			<Alert severity={type}>{message}</Alert>
		</Snackbar>
	);
};

export default Toast;
