import { createContext } from 'react';

import { ICampaign, ICommunityHubUser } from '../interfaces';
import {
	IGroupedCampaignsByCreator,
	IGroupedCampaignsByStatus,
} from '../pages/Dashboard/types';
import { ICampaignLeaderboardData } from '../pages/Campaign/types';

export interface ICampaignContext {
	campaigns: ICampaign[];
	statusCampaigns: IGroupedCampaignsByStatus;
	creatorCampaigns: IGroupedCampaignsByCreator;
	communityHubs: ICommunityHubUser[];
	campaignLeaderboard: ICampaignLeaderboardData[];
	setCampaigns: (campaigns: ICampaign[]) => void;
	setStatusCampaigns?: (campaignStatusMap: IGroupedCampaignsByStatus) => void;
	setCreatorCampaigns?: (
		campaignCreatorMap: IGroupedCampaignsByCreator
	) => void;
	setCommunityHubs?: (communityHubs: ICommunityHubUser[]) => void;
	setCampaignLeaderboard: (
		campaignLeaderboard: ICampaignLeaderboardData[]
	) => void;
}

export const CampaignContext = createContext<ICampaignContext>({
	campaigns: [],
	statusCampaigns: {},
	creatorCampaigns: {},
	communityHubs: [],
	campaignLeaderboard: [],
	setCampaigns: (campaigns: ICampaign[]) => {},
	setStatusCampaigns: (campaignStatusMap: IGroupedCampaignsByStatus) => {},
	setCreatorCampaigns: (campaignCreatorMap: IGroupedCampaignsByCreator) => {},
	setCommunityHubs: (communityHubs: ICommunityHubUser[]) => {},
	setCampaignLeaderboard: (
		campaignLeaderboard: ICampaignLeaderboardData[]
	) => {},
});
