import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

import { WalletEntryPosition } from '@particle-network/auth';
import {
	ArbitrumOne,
	ArbitrumSepolia,
	Polygon,
	PolygonAmoy,
	PolygonzkEVM,
	PolygonzkEVMCardona,
	Ethereum,
	BerachainArtio,
	Core,
	CoreTestnet,
	Base,
	BaseSepolia,
} from '@particle-network/chains';
import { evmWallets } from '@particle-network/connect';
import { ModalProvider } from '@particle-network/connect-react-ui';
import { config as dotEnvConfig } from 'dotenv';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './pages';
import { ToastProvider } from './contexts';

// Load ENV variables
dotEnvConfig({
	path:
		process.env.NODE_ENV === 'production'
			? '.env.production'
			: '.env.development',
});

ReactDOM.render(
	<BrowserRouter>
		<ModalProvider
			options={{
				projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID || '',
				clientKey: process.env.REACT_APP_PARTICLE_CLIENT_KEY || '',
				appId: process.env.REACT_APP_PARTICLE_APP_ID || '',
				chains: [
					Polygon,
					PolygonAmoy,
					PolygonzkEVM,
					PolygonzkEVMCardona,
					ArbitrumOne,
					ArbitrumSepolia,
					Ethereum,
					BerachainArtio,
					Core,
					CoreTestnet,
					Base,
					BaseSepolia,
				],
				particleWalletEntry: {
					displayWalletEntry: true,
					defaultWalletEntryPosition: WalletEntryPosition.BL,
					supportChains: [
						{ id: ArbitrumOne.id, name: ArbitrumOne.name },
						{ id: ArbitrumSepolia.id, name: ArbitrumSepolia.name },
						{ id: Polygon.id, name: Polygon.name },
						{ id: PolygonAmoy.id, name: PolygonAmoy.name },
						{ id: PolygonzkEVM.id, name: PolygonzkEVM.name },
						{
							id: PolygonzkEVMCardona.id,
							name: PolygonzkEVMCardona.name,
						},
						{ id: Ethereum.id, name: Ethereum.name },
						{ id: BerachainArtio.id, name: BerachainArtio.name },
						{ id: Core.id, name: Core.name },
						{ id: CoreTestnet.id, name: CoreTestnet.name },
						{ id: Base.id, name: Base.name },
						{ id: BaseSepolia.id, name: BaseSepolia.name },
					],
				},
				wallets: [
					...evmWallets({
						projectId: process.env
							.REACT_APP_WALLETCONNECT_PROJECT_ID as string,
						showQrModal: false,
					}),
				],
			}}
			theme={'auto'}
			language={'en'}
			walletSort={['Wallet', 'Particle Auth']}
			particleAuthSort={['email', 'phone', 'google', 'discord']}
		>
			<ToastProvider>
				<App />
			</ToastProvider>
		</ModalProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
