import { BigNumber, errors, ethers } from 'ethers';

import { Provider } from '@particle-network/connect';
import { IToastType } from '../contexts/ToastContext';
import {
	ICampaign,
	IUserAllocations,
	IUserEpochAllocation,
	TokenInfo,
} from '../interfaces';
import { IEpochBalance } from '../pages/Campaign/types';
import { IWeb2Client } from '../web2';
import {
	CHAIN_MAP,
	IAllocation,
	IMultiProof,
	IBrandOffer,
	ICampaignPayment,
	IPayment,
	format,
	getCumulativeAmountForToken,
	markdown,
	markup,
	tokens,
} from './web3Utils';

const { abi: epochCampaignAbi } = require('./EpochCampaign.json');
const { abi: factoryAbi } = require('./CampaignFactory.json');
const { abi: tokenAbi } = require('./PostmintPointToken.json');
const { abi: sigCampaignABI } = require('./SigCampaign.json');
const { abi: kolMarketplaceABI } = require('./KOLMarketplace.json');

require('dotenv').config();

export interface IWeb3Client {
	claimFromEpochContract: (
		userAddress: string,
		campaignAddress: string,
		funds: IAllocation[][],
		multiProofs: IMultiProof[],
		epochIds: number[]
	) => Promise<{ claimedEpochs?: any; claimedFunds?: any; status: string }>;
	claimFromSigCampaign: (
		nonce: number,
		userAddress: string,
		amount: string,
		signature: string
	) => Promise<{ amount?: number; budget?: number; status: string }>;
	getUserBalance: (userAddress: string) => Promise<BigNumber | undefined>;
	getTotalSupply: () => Promise<BigNumber | undefined>;
	createCampaignContract: (
		funds: IAllocation[],
		setContractState: (state: string) => void,
		userPublicAddress?: string
	) => Promise<{ contractAddress: string; hasAddedFunds: boolean }>;
	predictContractAddress: (
		funds: IAllocation[],
		userPublicAddress: string
	) => Promise<string>;
	getCampaignBalance: (
		campaignContractAddress?: string,
		userPublicAddress?: string,
		rewardTokenAddress?: string
	) => Promise<BigNumber | undefined>;
	getTokenBalance: (
		tokenContractAddress?: string,
		userPublicAddress?: string
	) => Promise<BigNumber | undefined>;
	getTokenInfo: (tokenContractAddress?: string) => Promise<
		| {
				name: string;
				symbol: string;
				decimals: number;
		  }
		| undefined
	>;
	getTokenDecimals: (
		tokenContractAddress?: string
	) => Promise<number | undefined>;
	doesCampaignHaveRoot: (campaignAddress: string) => Promise<boolean>;
	getContractAllowance: (
		userAddress: string,
		contractAddress: string,
		rewardTokenAddress: string
	) => Promise<BigNumber>;
	addCampaignFunds: (
		funds: IAllocation[],
		campaignAddress: string,
		userAddress: string,
		setContractState?: ((state: string) => void) | undefined
	) => Promise<boolean>;
	removeCampaignFunds: (
		funds: IAllocation,
		campaignAddress: string,
		userAddress: string,
		web2Client: IWeb2Client,
		campaignId: string,
		setContractState?: ((state: string) => void) | undefined
	) => Promise<ICampaign | undefined>;
	addPPTFunds: (
		value: string,
		userAddress: string,
		setContractState?: ((state: string) => void) | undefined
	) => Promise<BigNumber>;
	uploadRootToContract: (
		merkleRoot: string,
		campaignAddress: string
	) => Promise<string | undefined>;
	budgetDifference: (
		funds: IAllocation[],
		campaign: ICampaign,
		removedFunds: { token: string; amount: string }[] | undefined
	) => Promise<IAllocation[] | undefined>;
	uploadRootToContractWithFunds: (
		merkleRoot: string,
		funds: IAllocation[],
		campaignAddress: string
	) => Promise<string | undefined>;
	getAvailableCampaignBalance: (
		campaignContractAddress?: string,
		rewardTokenAddress?: string,
		epochAllocations?: { [epochId: string]: IUserAllocations },
		numEpochs?: string,
		campaignBalancesByEpoch?: { [epoch: string]: IEpochBalance[] },
		totalAllocatedFunds?: { [token: string]: string },
		removedFunds?: { token: string; amount: string }[] | undefined,
		claimedFunds?: { token: string; amount: string }[] | undefined
	) => Promise<BigNumber | undefined>;
	checkNecessaryFunds: (
		fund: IAllocation,
		userAddress: string,
		tokenInfos: { [token: string]: TokenInfo }
	) => Promise<boolean>;
	signMessage: (message: string) => Promise<string>;
	getDefaultCommissionBp: () => Promise<BigNumber | undefined>;
	getContractCommissionBp: (
		tokens: string[],
		userPublicAddress: string
	) => Promise<BigNumber | undefined>;
	getNecessaryFunds: (
		fund: IAllocation,
		userAddress: string,
		tokenInfos: { [token: string]: TokenInfo }
	) => Promise<{
		hasNecessaryFunds: boolean;
		userFunds: BigNumber | undefined;
		defaultCommissionBp: BigNumber | undefined;
		markedUpAmount: BigNumber | undefined;
		budgetAmount: BigNumber | undefined;
	}>;
	kolMarketplace: {
		makeOffers: (
			userAddress: string,
			signature: string,
			offers: IBrandOffer[],
			payments: IPayment[],
			setContractState?: ((state: string) => void) | undefined
		) => Promise<{
			offers: IBrandOffer[];
			startOfferID?: string;
			endOfferID?: string;
			status: string;
		}>;
		claimKOLPayments: (
			signature: string,
			offerIDs: string[]
		) => Promise<{ status: string }>;
		handleCampaignPayments: (
			signature: string,
			payments: ICampaignPayment[]
		) => Promise<{ status: string }>;
		getFee: () => Promise<BigNumber | undefined>;
	};
}

export function getWeb3Client(
	particleProvider: Provider,
	chainId: number,
	addToast: (message: string, type: IToastType) => void
): IWeb3Client {
	const provider = new ethers.providers.Web3Provider(
		particleProvider as any,
		'any'
	);
	const chain = CHAIN_MAP[chainId];
	if (!chain) {
		addToast('Please only use supported chains.', 'info');
	}
	// TO-DO
	const claimFromEpochContract = async (
		userAddress: string,
		campaignAddress: string,
		funds: IAllocation[][],
		multiProofs: IMultiProof[],
		epochIds: number[]
	): Promise<{ claimedEpochs?: any; status: string }> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);
		try {
			const tx = await campaignContract.claimEpochs(
				epochIds,
				userAddress,
				funds,
				multiProofs
			);
			const receipt = await tx.wait();

			// TODO: Why are the events not firing? This errors out
			// const events = receipt.events.find(
			// 	(event: { event: string }) => event.event === 'EpochClaimed'
			// );

			// const claimedEpochs = events.map(
			// 	(event: { args: { epoch: any } }) => {
			// 		const { epoch } = event?.args;
			// 		return epoch;
			// 	}
			// );

			// const claimedFunds = events.map(
			// 	(event: { args: { funds: any } }) => {
			// 		const { funds } = event?.args;
			// 		return funds;
			// 	}
			// );

			return {
				claimedEpochs: epochIds,
				status: 'SUCCESS',
			};
		} catch (e: any) {
			console.log(e);
			const message = e.error.data.message;
			console.log(JSON.stringify(message));
			if (e.message.includes('user rejected'))
				addToast('Transaction rejected', 'error');
			else addToast('Transaction failed', 'error');
			// TODO: Doesn't look like the RewardsAlreadyClaimed event gets emitted. Just execution reverted
			if (message.includes('RewardsAlreadyClaimed')) {
				return { status: 'ALREADY_CLAIMED' };
			}
			return { status: 'FAILED', claimedEpochs: epochIds };
		}
	};

	const claimFromSigCampaign = async (
		nonce: number,
		userAddress: string,
		rewardAmount: string,
		signature: string
	): Promise<{ amount?: number; status: string }> => {
		const contract = new ethers.Contract(
			chain.sigCampaignAddress,
			sigCampaignABI,
			provider.getSigner()
		);
		try {
			const tx = await contract.claim(
				nonce,
				userAddress,
				rewardAmount,
				signature
			);
			const receipt = await tx.wait();
			const event = receipt.events.find(
				(event: { event: string }) => event.event === 'Claim'
			);
			const { amount } = event.args;

			return {
				amount: parseFloat(format(amount)),
				status: 'SUCCESS',
			};
		} catch (e: any) {
			console.log(e);
			let message;
			if (e.error && e.error.data) {
				message = e.error.data.message;
			} else {
				message = e.message;
			}
			if (e.message.includes('user rejected'))
				addToast('Transaction rejected', 'error');
			else addToast('Transaction failed', 'error');
			if (message.indexOf('Rewards already claimed.') >= 0) {
				return { status: 'ALREADY_CLAIMED', amount: 0 };
			}
			return { status: 'FAILED', amount: 0 };
		}
	};

	const addPPTFunds = async (
		value: string,
		userAddress: string,
		setContractState?: (state: string) => void
	): Promise<BigNumber> => {
		const contract = new ethers.Contract(
			chain.sigCampaignAddress,
			sigCampaignABI,
			provider.getSigner()
		);

		const postmintToken = new ethers.Contract(
			chain.postmintTokenAddress,
			tokenAbi,
			provider.getSigner()
		);

		setContractState?.('APPROVING FUNDS');

		try {
			await postmintToken
				.connect(provider.getSigner())
				.approve(chain.sigCampaignAddress, `${value}`);

			setContractState?.('WAITING FOR ALLOWANCE');
			let hasApproved = false;
			while (!hasApproved) {
				const allowance = await getContractAllowance(
					userAddress,
					chain.sigCampaignAddress,
					postmintToken.address
				);
				if (allowance.gte(value)) {
					hasApproved = true;
				} else {
					await new Promise((r) => setTimeout(r, 5000));
				}
			}

			setContractState?.('ADDING FUNDS');

			const tx = await contract.addFunds(`${value}`).catch((e: any) => {
				console.log(e);
				if (e.message.includes('user rejected'))
					addToast('Transaction rejected', 'error');
				else addToast('Transaction failed', 'error');
			});
			await tx.wait();
			const balance = await contract.budget();
			return BigNumber.from(balance);
		} catch {
			return BigNumber.from(0);
		}
	};

	const getUserBalance = async (
		userAddress: string
	): Promise<BigNumber | undefined> => {
		const postmintToken = new ethers.Contract(
			chain.postmintTokenAddress,
			tokenAbi,
			provider.getSigner()
		);
		try {
			const balance = await postmintToken.balanceOf(userAddress);

			return balance;
		} catch {
			return undefined;
		}
	};

	const signMessage = (message: string) => {
		const signer = provider.getSigner();
		return signer.signMessage(message);
	};

	const getTotalSupply = async (): Promise<BigNumber | undefined> => {
		const postmintToken = new ethers.Contract(
			chain.postmintTokenAddress,
			tokenAbi,
			provider.getSigner()
		);
		try {
			const balance = await postmintToken.totalSupply();

			return balance;
		} catch (e) {
			return undefined;
		}
	};

	const getContractAllowance = async (
		userAddress: string,
		contractAddress: string,
		rewardTokenAddress: string
	): Promise<BigNumber> => {
		const rewardToken = new ethers.Contract(
			rewardTokenAddress,
			tokenAbi,
			provider.getSigner()
		);
		try {
			const balance = await rewardToken
				.connect(provider.getSigner())
				.allowance(userAddress, contractAddress);

			return BigNumber.from(balance);
		} catch {
			return BigNumber.from(0);
		}
	};

	const doesCampaignHaveRoot = async (
		campaignAddress: string
	): Promise<boolean> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);
		try {
			const root = await campaignContract.merkleRoots(0);
			return root.indexOf('000000000000') < 0;
		} catch {
			return false;
		}
	};

	const addCampaignFunds = async (
		funds: IAllocation[],
		campaignAddress: string,
		userAddress: string,
		setContractState?: (state: string) => void
	): Promise<boolean> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);

		try {
			setContractState?.('APPROVING FUNDS');
			const commissionBp = await campaignContract.commissionBp();
			if (commissionBp === undefined) {
				console.log('Could not query commission');
				return false;
			}
			// markup the funds with the commission
			const markedUpFunds = funds.map((fund) => ({
				...fund,
				amount: markup(
					commissionBp,
					BigNumber.from(fund.amount)
				).toString(),
			}));
			const aproveTokenPromises: Promise<void>[] = [];
			const approveToken = async (fund: IAllocation) => {
				const rewardToken = new ethers.Contract(
					fund.token,
					tokenAbi,
					provider.getSigner()
				);
				const allowance = await getContractAllowance(
					userAddress,
					campaignAddress,
					rewardToken.address
				);
				if (allowance.lt(fund.amount)) {
					const tx = await rewardToken
						.connect(provider.getSigner())
						.approve(campaignAddress, fund.amount);
					await tx.wait();
				}
			};
			markedUpFunds.forEach(async (fund) =>
				aproveTokenPromises.push(approveToken(fund))
			);
			await Promise.all(aproveTokenPromises);

			setContractState?.('ADDING FUNDS');

			const tx = await campaignContract
				.addFunds(markedUpFunds)
				.catch((e: any) => {
					console.log(e);
					if (e.message.includes('user rejected'))
						addToast('Transaction rejected', 'error');
					else addToast('Transaction failed', 'error');
				});
			await tx.wait();
			let success: boolean = true;
			const tokenBudgetPromises: Promise<void>[] = [];
			const checkTokenBudget = async (fund: IAllocation) => {
				const balance = await campaignContract.budgets(fund.token);
				success = success && BigNumber.from(balance).gte(fund.amount);
			};
			funds.forEach((fund) =>
				tokenBudgetPromises.push(checkTokenBudget(fund))
			);
			await Promise.all(tokenBudgetPromises);
			return success;
		} catch (err) {
			console.log(err);
			return false;
		}
	};

	const removeCampaignFunds = async (
		funds: IAllocation,
		campaignAddress: string,
		userAddress: string,
		web2Client: IWeb2Client,
		campaignId: string,
		setContractState?: (state: string) => void
	): Promise<ICampaign | undefined> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);

		try {
			setContractState?.('PAUSING CAMPAIGN');
			const paused = await campaignContract.paused();

			if (!paused) {
				const tx = await campaignContract.pause().catch((e: any) => {
					console.log(e);
					if (e.message.includes('user rejected'))
						addToast('Transaction rejected', 'error');
					else addToast('Transaction failed', 'error');
				});
				const pauseReceipt = await tx.wait();
			}

			setContractState?.('REMOVING FUNDS');

			const tx = await campaignContract
				.transferERC20(funds.token, userAddress, funds.amount)
				.catch((e: any) => {
					console.log(e);
					if (e.message.includes('user rejected'))
						addToast('Transaction rejected', 'error');
					else addToast('Transaction failed', 'error');
				});
			const receipt = await tx.wait();

			const success = receipt.status === 1;
			const txHash = receipt.transactionHash;

			let campaign: ICampaign | undefined = undefined;
			if (success) {
				try {
					campaign = await web2Client.campaigns.removeFunds(
						campaignId,
						funds,
						txHash
					);
				} catch (e) {
					console.log(e);
					addToast('Failed to update removed funds', 'error');
				}
				addToast('Removing funds was successful!', 'success');
			}

			setContractState?.('UNPAUSING CAMPAIGN');

			const unpauseTx = await campaignContract
				.unpause()
				.catch((e: any) => {
					console.log(e);
					if (e.message.includes('user rejected'))
						addToast('Transaction rejected', 'error');
					else addToast('Transaction failed', 'error');
				});
			await unpauseTx.wait();

			return campaign;
		} catch (err) {
			console.log(err);
			addToast('Removing funds failed!', 'error');
			return undefined;
		}
	};

	const createCampaignContract = async (
		funds: IAllocation[],
		setContractState: (state: string) => void,
		userPublicAddress?: string
	): Promise<{ contractAddress: string; hasAddedFunds: boolean }> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			userPublicAddress
		) {
			setContractState('DEPLOYING');
			try {
				const factoryContract = new ethers.Contract(
					chain.factoryAddress,
					factoryAbi,
					provider.getSigner()
				);

				const tokens = funds.map((fund) => fund.token);
				let campaignContractAddress = '';
				let hasAddedFunds = false;

				const predictedAddress =
					await factoryContract.predictCampaignAddress(
						tokens,
						userPublicAddress
					);

				const byteCode = await provider.getCode(predictedAddress);
				const contractDeployed = byteCode !== '0x';

				if (contractDeployed) {
					campaignContractAddress = predictedAddress;
				} else {
					const tx = await factoryContract.createEpochCampaign(
						tokens
					);
					const receipt = await tx.wait();
					const event = receipt.events.find(
						(event: { event: string }) =>
							event.event === 'EpochCampaignEvent'
					);
					const { campaignAddress } = event.args;
					campaignContractAddress = campaignAddress;
				}

				try {
					hasAddedFunds = await addCampaignFunds(
						funds,
						campaignContractAddress,
						userPublicAddress,
						setContractState
					);
				} catch {
					addToast('Failed to add funds! Please try again!', 'error');
				}

				return {
					contractAddress: campaignContractAddress,
					hasAddedFunds,
				};
			} catch (e: any) {
				console.log(e);
				if (e.message.includes('user rejected'))
					addToast('Transaction rejected', 'error');
				else addToast('Transaction failed', 'error');
				// addToast(JSON.stringify(e.error.data.message), 'error');
				return Promise.reject(e);
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const predictContractAddress = async (
		funds: IAllocation[],
		userPublicAddress: string
	): Promise<string> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			userPublicAddress
		) {
			try {
				const factoryContract = new ethers.Contract(
					chain.factoryAddress,
					factoryAbi,
					provider.getSigner()
				);

				const tokens = funds.map((fund) => fund.token);

				const predictedAddress =
					await factoryContract.predictCampaignAddress(
						tokens,
						userPublicAddress
					);
				return predictedAddress;
			} catch (e: any) {
				console.log(e);
				// addToast(JSON.stringify(e.error.data.message), 'error');
				return Promise.reject(e);
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const uploadRootToContract = (
		merkleRoot: string,
		campaignAddress: string
	): Promise<string | undefined> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);
		return campaignContract.uploadRoot(merkleRoot).then(async (tx: any) => {
			const receipt = await tx.wait();
			const event = receipt.events.find(
				(event: { event: string }) => event.event === 'EpochAdded'
			);
			const id = `${parseInt(event.args[0])}`;
			return id;
		});
	};

	const uploadRootToContractWithFunds = (
		merkleRoot: string,
		funds: IAllocation[],
		campaignAddress: string
	): Promise<string | undefined> => {
		const campaignContract = new ethers.Contract(
			campaignAddress,
			epochCampaignAbi,
			provider.getSigner()
		);
		return campaignContract
			.seedNewAllocations(merkleRoot, funds)
			.then(async (tx: any) => {
				const receipt = await tx.wait();
				const event = receipt.events.find(
					(event: { event: string }) => event.event === 'EpochAdded'
				);
				const id = `${parseInt(event.args[0])}`;
				return id;
			});
	};

	//
	const getCampaignBalance = async (
		campaignContractAddress?: string,
		userPublicAddress?: string,
		rewardTokenAddress?: string
	): Promise<BigNumber | undefined> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			userPublicAddress &&
			campaignContractAddress
		) {
			const contract = new ethers.Contract(
				campaignContractAddress,
				epochCampaignAbi,
				provider.getSigner()
			);
			try {
				const balance = await contract.budgets(rewardTokenAddress);
				return balance;
			} catch {
				return undefined;
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const getDefaultCommissionBp = async (): Promise<BigNumber | undefined> => {
		const factoryContract = new ethers.Contract(
			chain.factoryAddress,
			factoryAbi,
			provider.getSigner()
		);
		return await factoryContract.defaultCommissionBp();
	};

	const getContractCommissionBp = async (
		tokens: string[],
		userPublicAddress: string
	): Promise<BigNumber | undefined> => {
		const factoryContract = new ethers.Contract(
			chain.factoryAddress,
			factoryAbi,
			provider.getSigner()
		);
		const predictedAddress = await factoryContract.predictCampaignAddress(
			tokens,
			userPublicAddress
		);
		const byteCode = await provider.getCode(predictedAddress);
		const contractDeployed = byteCode !== '0x';
		if (contractDeployed) {
			const campaignContract = new ethers.Contract(
				predictedAddress,
				epochCampaignAbi,
				provider.getSigner()
			);
			return await campaignContract.commissionBp();
		} else {
			return getDefaultCommissionBp();
		}
	};

	/*
	 * Calculates the available campaign balance for a given token.
	 * This is calculated in the following way:
	 * Total amount of tokens transferred to the campaign contract - sum of all allocations for the token - future allocations for the token
	 * */
	const getAvailableCampaignBalance = async (
		campaignContractAddress?: string,
		rewardTokenAddress?: string,
		epochAllocations?: { [epochId: string]: IUserAllocations },
		numEpochs?: string,
		campaignBalancesByEpoch?: { [epoch: string]: IEpochBalance[] },
		totalAllocatedFunds?: { [token: string]: string },
		removedFunds?: { token: string; amount: string }[] | undefined,
		claimedFunds?: { token: string; amount: string }[] | undefined
	): Promise<BigNumber | undefined> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			campaignContractAddress &&
			rewardTokenAddress &&
			campaignBalancesByEpoch &&
			numEpochs
		) {
			const campaignContract = new ethers.Contract(
				campaignContractAddress,
				epochCampaignAbi,
				provider.getSigner()
			);
			try {
				let cumulativeAmount: BigNumber;
				try {
					cumulativeAmount = await getCumulativeAmountForToken(
						campaignContract,
						rewardTokenAddress
					);
				} catch (error) {
					console.log(error);
					console.log(
						'RPC issues detected while calculating available balance, proceed with caution, change RPC or contact support.'
					);
					const currentBudget = await campaignContract.budgets(
						rewardTokenAddress
					);
					cumulativeAmount = BigNumber.from(currentBudget ?? '0').add(
						claimedFunds?.find(
							(fund) => fund.token === rewardTokenAddress
						)?.amount ?? '0'
					);
				}
				// calculate balance by subtracting the cumulative amount from the already allocated amount
				let allocatedAmount = BigNumber.from(0);
				let numPassedEpochs = 0;
				if (epochAllocations) {
					allocatedAmount = Object.values(epochAllocations)
						.map((epoch: IUserAllocations) => Object.values(epoch))
						.flat()
						.map(
							(userAllocation: IUserEpochAllocation) =>
								userAllocation.allocations
						)
						.flat()
						.filter(
							(allocation: IAllocation) =>
								allocation.token === rewardTokenAddress
						)
						.reduce(
							(total, allocation) =>
								total.add(BigNumber.from(allocation.amount)),
							BigNumber.from(0)
						);

					numPassedEpochs = Object.keys(epochAllocations).length;
				}

				const numFutureEpochs = parseInt(numEpochs) - numPassedEpochs;
				for (let i = 0; i < numFutureEpochs; i++) {
					const epoch = `${numPassedEpochs + i}`;
					const epochBalances = campaignBalancesByEpoch[epoch];
					if (epochBalances) {
						allocatedAmount = allocatedAmount.add(
							BigNumber.from(
								epochBalances.find(
									(epochBalance) =>
										rewardTokenAddress ===
										epochBalance.tokenAddress
								)?.amount
							)
						);
					}
				}

				const totalTokenAllocatedAmount = totalAllocatedFunds
					? BigNumber.from(totalAllocatedFunds[rewardTokenAddress])
					: allocatedAmount;

				let removedFundsAmount = '0';
				const removedFundToken = removedFunds?.find(
					(fund) => fund.token === rewardTokenAddress
				);
				if (removedFundToken) {
					removedFundsAmount = removedFundToken.amount;
				}

				// Subtract the allocated amount and removed amount from
				// the cumulative amount to get the available balance
				const availableBalance = cumulativeAmount
					.sub(totalTokenAllocatedAmount)
					.sub(removedFundsAmount);
				return availableBalance.lte(0)
					? BigNumber.from(0)
					: availableBalance;
			} catch (err) {
				console.log(err);
				return Promise.reject(
					'Failed to calculate available campaign balance'
				);
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const getTokenBalance = async (
		tokenContractAddress?: string,
		userPublicAddress?: string
	): Promise<BigNumber | undefined> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			userPublicAddress &&
			tokenContractAddress
		) {
			const contract = new ethers.Contract(
				tokenContractAddress,
				tokenAbi,
				provider.getSigner()
			);
			try {
				const balance = await contract.balanceOf(userPublicAddress);
				return balance;
			} catch {
				return undefined;
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const getTokenInfo = async (
		tokenContractAddress?: string
	): Promise<
		{ name: string; symbol: string; decimals: number } | undefined
	> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			tokenContractAddress
		) {
			const contract = new ethers.Contract(
				tokenContractAddress,
				tokenAbi,
				provider.getSigner()
			);
			let name: string = '';
			let symbol: string = '';
			let decimals: number = 0;
			try {
				name = await contract.name();
				symbol = await contract.symbol();
				decimals = await contract.decimals();

				return { name, symbol, decimals };
			} catch {
				return { name, symbol, decimals };
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const getTokenDecimals = async (
		tokenContractAddress?: string
	): Promise<number | undefined> => {
		if (
			typeof (window as any).ethereum !== 'undefined' &&
			tokenContractAddress
		) {
			const contract = new ethers.Contract(
				tokenContractAddress,
				tokenAbi,
				provider.getSigner()
			);
			try {
				let decimals: number = await contract.decimals();

				return decimals;
			} catch {
				return undefined;
			}
		} else {
			return Promise.reject('Please install MetaMask');
		}
	};

	const checkNecessaryFunds = async (
		fund: IAllocation,
		userAddress: string,
		tokenInfos: { [token: string]: TokenInfo }
	): Promise<boolean> => {
		const defaultCommissionBp = await getContractCommissionBp(
			[fund.token],
			userAddress
		);
		// const defaultCommissionBp = await getDefaultCommissionBp();
		if (defaultCommissionBp === undefined) {
			console.log('Could not query default commission');
			return false;
		}
		const tokenInfo = tokenInfos[fund.token];
		if (tokenInfo === undefined) {
			console.log('Could not query token info');
			return false;
		}
		const userFunds = await getTokenBalance(fund.token, userAddress);
		const markedUpAmount = defaultCommissionBp.eq(BigNumber.from(0))
			? tokens(fund.amount, tokenInfo.decimals)
			: markup(
					defaultCommissionBp,
					BigNumber.from(tokens(fund.amount, tokenInfo.decimals))
			  );
		if (!userFunds || userFunds.lt(markedUpAmount)) return false;
		return true;
	};

	const getNecessaryFunds = async (
		fund: IAllocation,
		userAddress: string,
		tokenInfos: { [token: string]: TokenInfo }
	): Promise<{
		hasNecessaryFunds: boolean;
		userFunds: BigNumber | undefined;
		defaultCommissionBp: BigNumber | undefined;
		markedUpAmount: BigNumber | undefined;
		budgetAmount: BigNumber | undefined;
	}> => {
		const defaultCommissionBp = await getContractCommissionBp(
			[fund.token],
			userAddress
		);
		// const defaultCommissionBp = await getDefaultCommissionBp();
		if (defaultCommissionBp === undefined) {
			console.log('Could not query default commission');
			return {
				hasNecessaryFunds: false,
				userFunds: undefined,
				defaultCommissionBp: undefined,
				markedUpAmount: undefined,
				budgetAmount: undefined,
			};
		}
		const tokenInfo = tokenInfos[fund.token];
		if (tokenInfo === undefined) {
			console.log('Could not query token info');
			return {
				hasNecessaryFunds: false,
				userFunds: undefined,
				defaultCommissionBp: undefined,
				markedUpAmount: undefined,
				budgetAmount: undefined,
			};
		}
		const userFunds = await getTokenBalance(fund.token, userAddress);
		if (userFunds === undefined) {
			console.log('Could not query user funds');
			return {
				hasNecessaryFunds: false,
				userFunds: undefined,
				defaultCommissionBp: undefined,
				markedUpAmount: undefined,
				budgetAmount: undefined,
			};
		}
		const desiredBudgetAmount = BigNumber.from(fund.amount);
		const markedUpAmount = defaultCommissionBp.eq(BigNumber.from(0))
			? desiredBudgetAmount
			: markup(defaultCommissionBp, desiredBudgetAmount);

		const hasNecessaryFunds = userFunds.gte(markedUpAmount);
		const budgetAmount = hasNecessaryFunds
			? desiredBudgetAmount
			: markdown(defaultCommissionBp, userFunds);

		return {
			hasNecessaryFunds,
			userFunds,
			defaultCommissionBp,
			markedUpAmount,
			budgetAmount,
		};
	};

	/*
	 * This function will calculate and return the difference between the current
	 * budget and the funds passed in.
	 * */
	const budgetDifference = async (
		funds: IAllocation[],
		campaign: ICampaign,
		removedFunds: { token: string; amount: string }[] | undefined
	): Promise<IAllocation[] | undefined> => {
		if (!campaign.contractAddress) {
			return undefined;
		}
		const budgetDifferences: IAllocation[] = [];
		const getDiff = async (fund: IAllocation) => {
			const availableFunds = await getAvailableCampaignBalance(
				campaign.contractAddress,
				fund.token,
				campaign.epochAllocations,
				campaign.epochs,
				campaign.campaignBalancesByEpoch,
				undefined,
				removedFunds
			);

			if (availableFunds && availableFunds.gt(0)) {
				const difference = BigNumber.from(fund.amount).sub(
					availableFunds
				);
				budgetDifferences.push({
					amount: difference.toString(),
					token: fund.token,
				});
			}
		};
		try {
			const getDiffPromises: Promise<void>[] = [];
			funds.forEach((fund) => getDiffPromises.push(getDiff(fund)));
			await Promise.all(getDiffPromises);
			return budgetDifferences;
		} catch (err) {
			console.log(err);
			return undefined;
		}
	};

	const makeOffers = async (
		userAddress: string,
		signature: string,
		offers: IBrandOffer[],
		payments: IPayment[],
		setContractState?: (state: string) => void
	): Promise<{
		offers: IBrandOffer[];
		startOfferID?: string;
		endOfferID?: string;
		status: string;
	}> => {
		const contract = new ethers.Contract(
			chain.kolMarketplaceAddress,
			kolMarketplaceABI,
			provider.getSigner()
		);
		const approvePayment = async (payment: IPayment) => {
			const token = new ethers.Contract(
				payment.token,
				tokenAbi,
				provider.getSigner()
			);
			const allowance = await getContractAllowance(
				userAddress,
				contract.address,
				token.address
			);
			const totalAmount = BigNumber.from(payment.amount).add(payment.fee);
			if (allowance.lt(totalAmount)) {
				const tx = await token
					.connect(provider.getSigner())
					.approve(contract.address, totalAmount);
				await tx.wait();
			}
		};
		try {
			setContractState?.('APPROVING FUNDS');
			const commissionBp = await contract.getFee();
			if (commissionBp === undefined) {
				console.log('Could not query commission');
				return { offers, status: 'FAILED' };
			}
			await Promise.all(payments.map(approvePayment));
			const data = ethers.utils.defaultAbiCoder.encode(
				[
					'tuple(bytes,address,address,uint256)[]',
					'tuple(address,uint256, uint256)[]',
				],
				[
					offers.map((offer) => [
						`0x${offer.id}`,
						offer.kol,
						offer.token,
						offer.amount,
					]),
					payments.map((payment) => [
						payment.token,
						payment.amount,
						payment.fee,
					]),
				]
			);
			setContractState?.('MAKING OFFERS');
			const tx = await contract.makeOffers(signature, data);
			const receipt = await tx.wait();
			const event = receipt.events.find(
				(event: { event: string }) => event.event === 'CreatedOffers'
			);
			const { startOfferID, endOfferID } = event.args;
			return {
				offers,
				startOfferID: startOfferID.toString(),
				endOfferID: endOfferID.toString(),
				status: 'SUCCESS',
			};
		} catch (e: any) {
			console.log(e);
			if (e.message.includes('user rejected'))
				addToast('Transaction rejected', 'error');
			else addToast('Transaction failed', 'error');
			return { offers, status: 'FAILED' };
		}
	};

	const claimKOLPayments = async (
		signature: string,
		offerIDs: string[]
	): Promise<{ status: string }> => {
		const contract = new ethers.Contract(
			chain.kolMarketplaceAddress,
			kolMarketplaceABI,
			provider.getSigner()
		);
		try {
			const tx = await contract.claimKOLPayments(signature, offerIDs);
			await tx.wait();
			return {
				status: 'SUCCESS',
			};
		} catch (e: any) {
			console.log(e);
			if (e.message.includes('user rejected'))
				addToast('Transaction rejected', 'error');
			else addToast('Transaction failed', 'error');
			return { status: 'FAILED' };
		}
	};

	const handleCampaignPayments = async (
		signature: string,
		payments: ICampaignPayment[]
	): Promise<{ status: string }> => {
		const contract = new ethers.Contract(
			chain.kolMarketplaceAddress,
			kolMarketplaceABI,
			provider.getSigner()
		);
		try {
			let offerIDs: string[] = [];
			let payKOLs: boolean[] = [];
			payments.forEach((payment) => {
				offerIDs.push(payment.offerID);
				payKOLs.push(payment.payKOL);
			});
			const tx = await contract.handleCampaignPayments(
				signature,
				offerIDs,
				payKOLs
			);
			await tx.wait();
			return {
				status: 'SUCCESS',
			};
		} catch (e: any) {
			console.log(e);
			if (e.message.includes('user rejected'))
				addToast('Transaction rejected', 'error');
			else addToast('Transaction failed', 'error');
			return { status: 'FAILED' };
		}
	};

	const getFee = async (): Promise<BigNumber | undefined> => {
		if (chain.kolMarketplaceAddress) {
			const contract = new ethers.Contract(
				chain.kolMarketplaceAddress,
				kolMarketplaceABI,
				provider.getSigner()
			);
			return await contract.getFee();
		}
		return undefined;
	};

	return {
		claimFromEpochContract,
		claimFromSigCampaign,
		getUserBalance,
		getTotalSupply,
		createCampaignContract,
		predictContractAddress,
		getCampaignBalance,
		getTokenBalance,
		getTokenInfo,
		getTokenDecimals,
		doesCampaignHaveRoot,
		getContractAllowance,
		addCampaignFunds,
		removeCampaignFunds,
		addPPTFunds,
		uploadRootToContract,
		budgetDifference,
		uploadRootToContractWithFunds,
		getAvailableCampaignBalance,
		checkNecessaryFunds,
		signMessage,
		getDefaultCommissionBp,
		getContractCommissionBp,
		getNecessaryFunds,
		kolMarketplace: {
			makeOffers,
			claimKOLPayments,
			handleCampaignPayments,
			getFee,
		},
	};
}
