import { ICampaign } from '../interfaces';
import { IAffiliateData, IGateTokenForm } from '../pages/Campaign/types';
import { IWeb2Client } from '../web2';
import { IWeb3Client } from '../web3/web3Client';

const uploadAllocationsForEpoch = async (
	epoch: number,
	campaign: ICampaign,
	web2Client: IWeb2Client,
	web3Client: IWeb3Client
) => {
	if (!campaign.contractAddress) {
		throw Error('Campaign has no address');
	}
	if (!campaign.epochRoots) {
		throw Error('Campaign has no roots');
	}
	const epochRoot = campaign.epochRoots[epoch.toString()];
	if (epochRoot.uploaded) {
		throw Error('Root uploaded');
	}
	const merkleRoot = epochRoot.root;
	const epochId = await web3Client.uploadRootToContract(
		merkleRoot,
		campaign.contractAddress as string
	);
	const returnedCampaign = await web2Client.campaigns.uploadAllocations(
		campaign.id!,
		epochId,
		epoch
	);
	if (returnedCampaign) {
		return returnedCampaign;
	}
	return campaign;
};

export const generateAndUploadAllocationsForEpoch = (
	epoch: number,
	campaign: ICampaign,
	web2Client: IWeb2Client,
	web3Client: IWeb3Client
) => {
	let promise: Promise<{ campaign: ICampaign }>;
	return uploadAllocationsForEpoch(
		epoch,
		campaign,
		web2Client,
		web3Client
	).catch((err) => {
		console.log(err.message);
		if (err.code === 'ACTION_REJECTED') return Promise.reject(err);
		console.log('Nothing to upload, generating first...');
		if (campaign.type === 'affiliate') {
			promise = web2Client.campaigns.generateAffiliateEpochAllocations(
				campaign.id
			);
		} else {
			promise = web2Client.campaigns.generateEpochAllocations(
				campaign.id
			);
		}
		return promise.then(async ({ campaign }) =>
			uploadAllocationsForEpoch(epoch, campaign, web2Client, web3Client)
		);
	});
};

export const loadTokenInfo = async (
	campaign: ICampaign,
	token: string,
	web3Client: IWeb3Client
) => {
	const tokenInfo =
		campaign.tokenInfos[token] ?? (await web3Client.getTokenInfo(token));
	return tokenInfo;
};

export const initialNumParticipants = (
	campaign: ICampaign,
	affiliateData: IAffiliateData
) => {
	if (affiliateData && campaign.startAt < Date.now()) {
		const numParticipants = Object.keys(affiliateData).length;
		if (numParticipants > 0) {
			return numParticipants;
		}
	}
	return 10;
};
export const generateMultiTokenGate = (
	multipleGateTokens: IGateTokenForm[]
) => {
	const tokens = [];
	let rule = '';
	let i = 0;
	for (let token of multipleGateTokens) {
		tokens.push({
			tokenAddress: token.address,
			chainId: token.chain,
			threshold: token.amount,
			scoring: token.scoring,
			joining: token.joining,
		});
		if (rule === '') {
			// for the first token we don't need to add the | operator
			rule += `${i++}`;
		} else {
			// for the rest of the tokens we need to add the | operator
			rule += `|${i++}`;
		}
	}
	return {
		tokens,
		rule,
	};
};
