import { IToast } from '../contexts/ToastContext'
import { useToast } from '../hooks';
import Toast from './Toast'

export const ToastContainer = ({ toasts }: { toasts: IToast[] }) => {
    const { removeToast } = useToast();
    return (
       <>
            {toasts.map((toast) => (
                <Toast key={toast.id} isOpen={true} message={toast.message} type={toast.type} handleClose={() => { removeToast(toast.id) }} />
            ))}
       </>
    )
}
