import { AxiosInstance } from 'axios';
import { IAccessCode, ICampaign, ICampaignSelectBoxItem, IKolOffer, IOffer, IUser } from '../interfaces';
import { IBrandOffer, ICampaignPayment, IPayment } from '../web3/web3Utils';

type IKolInfo = IUser["kolInfo"];

export interface IKolMarketplacesRoutes {
    generateAccessCode: (type: "marketplace" | string, permanent: boolean, forFeaturedKOL: boolean, forBrand: boolean) => Promise<IAccessCode>;
    getAccessCodeById: (accessCodeId: string) => Promise<IAccessCode>;
    getAccessCodesByUserId: (userId: string) => Promise<IAccessCode[]>;
    joinMarketplace: (accessCodeId: string, kolInfo: IKolInfo, creditsImage?: Blob) => Promise<{ user: IUser, accessCodes: IAccessCode[] }>;
    onboardBrand: (accessCodeId: string) => Promise<IUser>;
    bookmarkKol: (userKolId: string) => Promise<IUser>;
    getKolUsers: () => Promise<IUser[]>;
    getCreditImage: (creditImageId: string) => Promise<Buffer>;
    inviteKols: (campaignId: string, kolOffers: IKolOffer[]) => Promise<{ signature: string, offers: IBrandOffer[], payments: IPayment[] }>;
    makeOffers: () => Promise<void>;
    getMakeOffersInfo: (campaignId: string) => Promise<{ signature: string, offers: IBrandOffer[], payments: IPayment[] }>; // for retrying offers
    getMyOffers: () => Promise<{ offer: IOffer, campaign: ICampaign }[]>;
    getOffersForCampaignId: (campaignId: string) => Promise<{ offer: IOffer, kolUser: IUser }[]>;
    getMyKOLCampaigns: () => Promise<ICampaignSelectBoxItem[]>;
    respondToOffer: (offerId: string, response: string) => Promise<IOffer>;
    submitPost: (offerId: string, url: string, postType?: string) => Promise<IOffer>;
    fulfillOffer: (offerId: string) => Promise<IOffer>;
    getClaimKOLPaymentsInfo: () => Promise<{ signature: string, offerIDs: string[] }>;
    claimKOLPayments: () => Promise<void>;
    getHandleCampaignPaymentsInfo: (campaignId: string) => Promise<{ signature: string, payments: ICampaignPayment[] }>
    handleCampaignPayments: (campaignId: string) => Promise<void>;
}

export function getKolMarketplaceRoutes(client: AxiosInstance): IKolMarketplacesRoutes {
    const generateAccessCode: (
        type: "marketplace" | string,
        permanent: boolean,
        forFeaturedKOL: boolean,
        forBrand: boolean
    ) => Promise<IAccessCode> = (
        type: "marketplace" | string,
        permanent: boolean,
        forFeaturedKOL: boolean,
        forBrand: boolean
    ) =>
            client
                .post(`/kol/generateAccessCode`, { type, permanent, forFeaturedKOL, forBrand })
                .then((res) => res.data);

    const getAccessCodeById: (
        accessCodeId: string
    ) => Promise<IAccessCode> = (accessCodeId: string) =>
            client.get(`/kol/accessCode/${accessCodeId}`).then((res) => res.data);

    const getAccessCodesByUserId: (
        userId: string
    ) => Promise<IAccessCode[]> = (userId: string) =>
            client.get(`/kol/accessCodes/${userId}`).then((res) => res.data);

    const joinMarketplace: (
        accessCodeId: string,
        kolInfo: IKolInfo,
        creditsImage?: Blob,
    ) => Promise<{ user: IUser, accessCodes: IAccessCode[] }> =
        (accessCodeId: string, kolInfo: IKolInfo, creditsImage: Blob | undefined) => {
            const data = new FormData();
            data.append('accessCodeId', accessCodeId);
            data.append('kolInfo', JSON.stringify(kolInfo));
            if (creditsImage) {
                data.append('creditsImage', creditsImage);
            }
            return client
                .post(`/kol/joinMarketplace`, data)
                .then((res) => res.data);
        }

    const onboardBrand: (
        accessCodeId: string,
    ) => Promise<IUser> = (accessCodeId: string) =>
            client
                .post(`/kol/onboardBrand`, { accessCodeId })
                .then((res) => res.data);

    const bookmarkKol: (
        userKolId: string
    ) => Promise<IUser> = (userKolId: string) =>
            client.get(`/kol/bookmark/${userKolId}`).then((res) => res.data);

    const getKolUsers: (
    ) => Promise<IUser[]> = () =>
            client.get(`/kol/users`).then((res) => res.data);

    const getCreditImage: (
        creditImageId: string
    ) => Promise<Buffer> = (creditImageId: string) =>
            client.get(`/kol/creditImage/${creditImageId}`).then((res) => res.data);

    const inviteKols: (
        campaignId: string,
        kolOffers: IKolOffer[]
    ) => Promise<{ signature: string, offers: IBrandOffer[], payments: IPayment[] }> = (campaignId: string, kolOffers: IKolOffer[]) =>
            client
                .post(`/kol/inviteKOLs/${campaignId}`, { kolOffers })
                .then((res) => res.data);

    const getMyOffers: (
    ) => Promise<{ offer: IOffer, campaign: ICampaign }[]> = () =>
            client.get(`/kol/getOffers`).then((res) => res.data);

    const getMakeOffersInfo: (
        campaignId: string
    ) => Promise<{ signature: string, offers: IBrandOffer[], payments: IPayment[] }> = (campaignId: string) =>
            client.get(`/kol/getMakeOffersInfo/${campaignId}`).then((res) => res.data);

    const getOffersForCampaignId: (
        campaignId: string
    ) => Promise<{ offer: IOffer, kolUser: IUser }[]> = (campaignId: string) =>
            client.get(`/kol/getOffers/${campaignId}`).then((res) => res.data);

    const getMyKOLCampaigns: (
    ) => Promise<{ id: string, name: string }[]> = () =>
            client.get(`/kol/getMyKOLCampaigns`).then((res) => res.data);

    const respondToOffer: (
        offerId: string,
        response: string
    ) => Promise<IOffer> = (offerId: string, response: string) =>
            client
                .post(`/kol/respondToOffer/${offerId}`, { response })
                .then((res) => res.data);

    const submitPost: (
        offerId: string,
        url: string,
        postType?: string,
    ) => Promise<IOffer> = (offerId: string, url: string, postType?: string) =>
            client
                .post(`/kol/submitPost`, { offerId, url, postType })
                .then((res) => res.data);

    const fulfillOffer: (
        offerId: string,
    ) => Promise<IOffer> = (offerId: string) =>
            client
                .post(`/kol/fulfillOffer/${offerId}`)
                .then((res) => res.data);

    const makeOffers: () => Promise<void> = () =>
        client
            .post(`/kol/makeOffers`)
            .then((res) => res.data);

    const getClaimKOLPaymentsInfo: () => Promise<{ signature: string, offerIDs: string[] }> = () =>
        client.get(`/kol/getClaimKOLPaymentsInfo`).then((res) => res.data);

    const claimKOLPayments: () => Promise<void> = () =>
        client
            .get(`/kol/claimKOLPayments`)
            .then((res) => res.data);

    const getHandleCampaignPaymentsInfo: (
        campaignId: string
    ) => Promise<{ signature: string, payments: ICampaignPayment[] }> = (campaignId: string) =>
            client.get(`/kol/getHandleCampaignPaymentsInfo/${campaignId}`).then((res) => res.data);

    const handleCampaignPayments: (
        campaignId: string
    ) => Promise<void> = (campaignId: string) =>
            client
                .get(`/kol/handleCampaignPayments/${campaignId}`)
                .then((res) => res.data);

    return {
        generateAccessCode,
        getAccessCodeById,
        getAccessCodesByUserId,
        joinMarketplace,
        onboardBrand,
        bookmarkKol,
        getKolUsers,
        getCreditImage,
        inviteKols,
        makeOffers,
        getMakeOffersInfo,
        getMyOffers,
        getOffersForCampaignId,
        getMyKOLCampaigns,
        respondToOffer,
        submitPost,
        fulfillOffer,
        getClaimKOLPaymentsInfo,
        claimKOLPayments,
        getHandleCampaignPaymentsInfo,
        handleCampaignPayments
    };
}
