import { createTheme } from '@mui/material';

export const MuiTheme = createTheme({
	palette: {
		primary: {
			light: '#0f0f0f',
			main: '#0f0f0f',
			dark: '#24282C',
			contrastText: '#0F0F0F',
		},
		secondary: {
			light: '#E4ECF7',
			main: '#E4ECF7',
			dark: '#0F0F0F',
			contrastText: '#E4ECF7',
		},
		error: {
			main: '#FF5154',
		},
		warning: {
			main: '#FF971D',
		},
		info: {
			main: '#00DCE9',
		},
		success: {
			main: '#39D6A4',
		},
	},
	typography: {
		allVariants: {
			color: '#24282C',
			fontFamily: 'Plus Jakarta Sans, sans-serif',
			textAlign: 'left',
		},
		button: {
			color: '#ffffff',
			textTransform: 'capitalize',
		},
	},
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: {
					backgroundColor: '#ffffff',
					color: '#000000',
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				input: {
					backgroundColor: '#000000',
					color: '#ffffff',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '12px',
					backgroundColor: '#000000',
					color: '#ffffff',
					height: 60,
					'&:hover': {
						backgroundColor: '#000000',
						opacity: 0.8,
					},
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					'&.MuiCheckbox-root.Mui-checked': {
						color: '#24282C',
					}
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: '24px',
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						color: '#ffffff',
						backgroundColor: '#1976d2',
						'&:hover': {
							color: '#ffffff',
							backgroundColor: '#1976d2',
						},
					},
				},
			},
		},
		MuiPickersMonth: {
			styleOverrides: {
				monthButton: {
					'&.Mui-selected': {
						color: '#ffffff',
						backgroundColor: '#1976d2',
						'&:hover': {
							color: '#ffffff',
							backgroundColor: '#1976d2',
						},
					},
				},
			},
		},
		MuiPickersYear: {
			styleOverrides: {
				yearButton: {
					'&.Mui-selected': {
						color: '#ffffff',
						backgroundColor: '#1976d2',
						'&:hover': {
							color: '#ffffff',
							backgroundColor: '#1976d2',
						},
					},
				},
			},
		},
		MuiTimePickerToolbar: {
			styleOverrides: {
				root: {
					'& .MuiButton-root': {
						color: '#1976d2',
						backgroundColor: 'transparent',
						'&:hover': {
							backgroundColor: 'rgba(25, 118, 210, 0.04)',
							textDecoration: 'none',
						},
					},
				},
			},
		},
		MuiTimeClock: {
			styleOverrides: {
				root: {
					'& .MuiClock-pin': {
						backgroundColor: '#1976d2',
					},
					'& .MuiClockPointer-root': {
						backgroundColor: '#1976d2',
					},
					'& .MuiClockPointer-thumb': {
						backgroundColor: 'rgb(255, 255, 255)',
						border: '16px solid rgb(25, 118, 210)',
					},
				},
			},
		},
		MuiToggleButtonGroup: {
			styleOverrides: {
				root: {
					color: 'green',
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					'& .MuiButton-root': {
						color: '#1976d2',
						fontWeight: 500,
						textTransform: 'uppercase',
						backgroundColor: 'transparent',
						'&:hover': {
							backgroundColor: 'rgba(25, 118, 210, 0.04)',
							textDecoration: 'none',
						},
					},
				},
			},
		},
	},
});
