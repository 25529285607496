import { IUser } from '../../interfaces';
import { isAddress } from 'ethers/lib/utils';
import { formatAddress } from '../../web3/web3Utils';
import { AZURE_STORAGE, imageUsageTypes } from '../../utils/imageUtils';

export const getUserProfilePicture = (
	userId: string,
	twitterProfileImageUrl?: string,
	profilePictureUrlPath?: string
) => {
	if (profilePictureUrlPath)
		return `${AZURE_STORAGE}/${profilePictureUrlPath}`;
	if (twitterProfileImageUrl !== undefined)
		return twitterProfileImageUrl.replace('_normal', '');
	return `${AZURE_STORAGE}/${imageUsageTypes.profilePicture}-${userId}`;
	// return twitterProfileImageUrl
	// 	? twitterProfileImageUrl
	// 	: profilePictureUrlPath
	// 	? `${AZURE_STORAGE}/${profilePictureUrlPath}`
	// 	: `${AZURE_STORAGE}/${imageUsageTypes.profilePicture}-${userId}`;
};

export const getFallbackUserProfilePicture = (
	userId: string,
	twitterProfileImageUrl?: string
) => {
	return `${
		process.env.REACT_APP_BACKEND_URL
	}/users/profilePicture/${userId}?twitterUrl=${encodeURIComponent(
		twitterProfileImageUrl ?? ''
	)}`;
};

export const getFallbackGradientUserProfilePicture = (userId: string) => {
	return `https://avatar.vercel.sh/${userId}`;
};

export const getUserProfileBanner = (
	userId: string,
	userProfileBannerURLPath?: string
) => {
	return userProfileBannerURLPath
		? `${AZURE_STORAGE}/${userProfileBannerURLPath}`
		: `${AZURE_STORAGE}/${imageUsageTypes.profileBanner}-${userId}`;
};

export const getFallbackUserProfileBanner = (profileBannerId: string) => {
	return `${process.env.REACT_APP_BACKEND_URL}/users/profileBanner/${profileBannerId}`;
};

export const getUserDisplayName = (user: IUser) => {
	return isAddress(user.username as string)
		? formatAddress(user.username as string)
		: user.username || user.publicAddress;
};

export const validateEmail = (email: string) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(email);
};

export const validateTwitterUsername = (twitterUsername: string) => {
	const twitterRegex = /^[A-Za-z0-9_]{1,15}$/i;
	return twitterRegex.test(twitterUsername);
};

export const getSecondsFromId = (id: string) => {
	return parseInt(id.toString().substring(0, 8), 16);
};

export const getDateFromId = (id: string) => {
	return new Date(getSecondsFromId(id) * 1000);
};
