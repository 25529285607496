import axios, { AxiosRequestConfig } from 'axios';
import { getCampaignsRoutes, ICampaignsRoutes } from './campaignsRoutes';
import { getTwitterRoutes, ITwitterRoutes } from './twitterRoutes';
import { getUsersRoutes, IUsersRoutes } from './usersRoutes';
import { getReferralRoutes, IReferralRoutes } from './referralRoutes';
import { getKolMarketplaceRoutes, IKolMarketplacesRoutes } from './kolMarketplaceRoutes';
import { getImagesRoutes, IImagesRoutes } from "./imagesRoutes";

export interface IWeb2Client {
	users: IUsersRoutes;
	campaigns: ICampaignsRoutes;
	twitter: ITwitterRoutes;
	referrals: IReferralRoutes;
	kolMarketplace: IKolMarketplacesRoutes;
	images: IImagesRoutes;
}

export function getWeb2Client(authToken: string, chainId: number): IWeb2Client {
	const config: AxiosRequestConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${authToken}`,
			ChainId: chainId,
		},
		withCredentials: true,
		baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
	};

	const client = axios.create(config);

	return {
		twitter: getTwitterRoutes(client),
		campaigns: getCampaignsRoutes(client),
		users: getUsersRoutes(client),
		referrals: getReferralRoutes(client),
		kolMarketplace: getKolMarketplaceRoutes(client),
		images: getImagesRoutes(client)
	};
}
