import { useState, useEffect } from 'react';

export const usePageVisibility = (): boolean => {
	const [visibilityStatus, setVisibilityStatus] = useState<boolean>(!document.hidden);

	useEffect(() => {
		function handleVisibilityChange() {
			setVisibilityStatus(!document.hidden);
		}

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			// Since we checked that visibilityChange is not undefined, TypeScript knows it's a string here
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	return visibilityStatus;
}
