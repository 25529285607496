import { FC, createContext, useState } from 'react';
import { ToastContainer } from '../components/ToastContainer';

export type IToastType = 'info' | 'success' | 'error';

export interface IToast {
	id: number;
	message: string;
	type: IToastType;
}

export interface IToastContext {
	addToast: (message: string, type: IToastType) => void;
	removeToast: (id: number) => void;
}

export const ToastContext = createContext<IToastContext | undefined>(undefined);

export const ToastProvider: FC = ({ children }) => {
	const [toasts, setToasts] = useState<IToast[]>([]);

	const addToast = (message: string, type: IToastType = 'info') => {
		const toast: IToast = {
			id: Date.now(),
			message,
			type,
		};
		setToasts((prevToasts) => [...prevToasts, toast]);
	};

	const removeToast = (id: number) => {
		setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
	};

	const toastContextValue: IToastContext = {
		addToast,
		removeToast,
	};

	return (
		<ToastContext.Provider value={toastContextValue}>
			{children}
			<ToastContainer toasts={toasts} />
		</ToastContext.Provider>
	)
}

