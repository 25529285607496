import { AxiosInstance } from 'axios';
import { IBaseUser, ITwitterStats, IUser } from '../interfaces';
import { IParticipantInfo } from '../pages/CampaignInsights/types';
import { IUserLeaderboardData } from '../pages/Dashboard/types';
import { RawDraftContentState } from 'draft-js';
import { getImagesRoutes } from './imagesRoutes';
import { imageUsageTypes } from '../utils/imageUtils';

export interface IUsersRoutes {
	getSelf: () => Promise<IUser>;
	getByUserId: (userId: string) => Promise<IBaseUser>;
	getByUserPublicAddress: (publicAddress: string) => Promise<IBaseUser>;
	getAlphaResponses: () => Promise<{
		[address: string]: IParticipantInfo;
	}>;
	approveUser: (
		userId: string,
		approvedUserId: string
	) => Promise<IParticipantInfo>;
	getUserProfileBanner: (userId: string) => Promise<Buffer>;
	patchUser: (
		userId: string,
		username?: string,
		twitterUsername?: string,
		email?: string
	) => Promise<IUser>;
	updateUser: (
		userId: string,
		username?: string,
		twitterUsername?: string,
		email?: string,
		profilePicture?: Blob,
		profileBanner?: Blob,
		description?: RawDraftContentState
	) => Promise<IUser>;
	getFluidToken: (
		userId: string,
		username: string,
		tenantId: string,
		documentId: string | undefined,
		additionalDetails: Object
	) => Promise<string>;
	mintPoints: (
		userId: string,
		publicAddress: string,
		amount: number
	) => Promise<void>;
	getUserLeaderboard: (limit: number) => Promise<IUserLeaderboardData[]>;
	unsubscribeFromEmails: () => Promise<void>;
	resubscribeFromEmails: () => Promise<void>;
	getUserStats: (
		userId: string
	) => Promise<{ twitterStats: ITwitterStats; createdAt: number }[]>;
}

export function getUsersRoutes(client: AxiosInstance): IUsersRoutes {
	const getAlphaResponses: () => Promise<{
		[address: string]: IParticipantInfo;
	}> = () => client.get(`/users/alphaResponses`).then((res) => res.data);

	const approveUser: (
		userId: string,
		approvedUserId: string
	) => Promise<IParticipantInfo> = (userId, approvedUserId) =>
		client
			.post(`/users/approveUser`, {
				userId,
				approvedUserId,
			})
			.then((res) => res.data);

	const getUserLeaderboard: (
		limit: number
	) => Promise<IUserLeaderboardData[]> = (limit) =>
		client.get(`/users/userLeaderboard/${limit}`).then((res) => res.data);

	const getUserProfileBanner: (profileBannerId: string) => Promise<Buffer> = (
		profileBannerId
	) =>
		client
			.get(`/users/profileBanner/${profileBannerId}`)
			.then((res) => res.data);

	const getSelf: () => Promise<IUser> = () =>
		client.get('/users/self').then((res) => res.data);

	const patchUser: (
		userId: string,
		username?: string,
		twitterUsername?: string,
		email?: string
	) => Promise<IUser> = (userId, username, twitterUsername, email) =>
		client
			.patch(`/users/${userId}`, {
				username,
				twitterUsername,
				email,
			})
			.then((res) => res.data);

	const updateUser: (
		userId: string,
		username?: string,
		twitterUsername?: string,
		email?: string,
		profilePicture?: Blob,
		profileBanner?: Blob,
		description?: RawDraftContentState
	) => Promise<IUser> = (
		userId,
		username,
		twitterUsername,
		email,
		profilePicture,
		profileBanner,
		description
	) => {
		const data = new FormData();
		if (username) {
			data.append('username', username);
		}
		if (twitterUsername) {
			data.append('twitterUsername', twitterUsername);
		}
		if (email) {
			data.append('email', email);
		}
		if (description) {
			data.append('description', JSON.stringify(description));
		}
		let uploadImagePromises = [];
		if (profilePicture) {
			// data.append('profilePicture', profilePicture); // upload to backend
			uploadImagePromises.push(
				getImagesRoutes(client).uploadImage(
					imageUsageTypes.profilePicture,
					profilePicture
				)
			); // upload image to cdn
		}
		if (profileBanner) {
			// data.append('profileBanner', profileBanner); // upload to backend
			uploadImagePromises.push(
				getImagesRoutes(client).uploadImage(
					imageUsageTypes.profileBanner,
					profileBanner
				)
			); // upload image to cdn
		}
		return Promise.all(uploadImagePromises)
			.then(() => new Promise((r) => setTimeout(r, 500))) // sleep
			.then(() => client.post(`/users/update/${userId}`, data))
			.then((res) => res.data);
	};

	const getFluidToken: (
		userId: string,
		username: string,
		tenantId: string,
		documentId: string | undefined,
		additionalDetails: Object
	) => Promise<string> = (
		userId,
		username,
		tenantId,
		documentId,
		additionalDetails
	) =>
		client
			.get('/users/fluidToken', {
				params: {
					tenantId,
					documentId,
					userId: userId,
					userName: username,
					additionalDetails: additionalDetails,
				},
			})
			.then((res) => res.data);

	const mintPoints: (
		userId: string,
		publicAddress: string,
		amount: number
	) => Promise<void> = (userId, publicAddress, amount) =>
		client
			.post('/users/mintPoints', { userId, publicAddress, amount })
			.then((res) => res.data);

	const getByUserId: (userId: string) => Promise<IBaseUser> = (userId) =>
		client
			.get(`/users/getByUserId?userId=${userId}`)
			.then((res) => res.data);

	const getByUserPublicAddress: (
		publicAddress: string
	) => Promise<IBaseUser> = (publicAddress) =>
		client
			.get(`/users/getByUserPublicAddress?publicAddress=${publicAddress}`)
			.then((res) => res.data);

	const unsubscribeFromEmails: () => Promise<void> = () =>
		client.post('/users/emailUnsubscribe').then((res) => res.data);

	const resubscribeFromEmails: () => Promise<void> = () =>
		client.post('/users/emailResubscribe').then((res) => res.data);

	const getUserStats: (
		userId: string
	) => Promise<{ twitterStats: ITwitterStats; createdAt: number }[]> = (
		userId
	) => client.get(`/users/stats/${userId}`).then((res) => res.data);

	return {
		getSelf,
		getAlphaResponses,
		approveUser,
		getUserProfileBanner,
		patchUser,
		updateUser,
		getFluidToken,
		mintPoints,
		getUserLeaderboard,
		getByUserId,
		getByUserPublicAddress,
		unsubscribeFromEmails,
		resubscribeFromEmails,
		getUserStats,
	};
}
