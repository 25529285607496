import { Stack, Typography } from '@mui/material';

import tokenIconBlack from '../assets/token-icon-black.svg';
import { CSSProperties } from 'react';

const TokenAmountBox = ({
	title,
	value,
}: {
	title: string;
	value: string;
}): JSX.Element => {
	return (
		<Stack
			direction={'column'}
			justifyContent={'space-between'}
			alignItems={'flex-start'}
			spacing={0.1}
			style={{
				...styles.container,
			}}
		>
			<Typography style={{ ...styles.titleText }}>{title}</Typography>
			<Stack
				direction={'row'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				spacing={1}
			>
				<img src={tokenIconBlack} height={18} />
				<Typography
					sx={{
						...styles.amountText,
						fontSize: {
							xs: '18px',
							md: '15px',
							lg: '16px',
							xl: '18px',
						},
					}}
				>
					{`${value} PPT`}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default TokenAmountBox;

const styles = {
	container: {
		border: '1px solid #E8ECF3',
		borderRadius: '12px',
		padding: '6px 8px',
		backgroundColor: '#FFFFFF',
	} as CSSProperties,
	titleText: {
		fontWeight: 600,
		fontSize: '12px',
	} as CSSProperties,
	amountText: {
		fontWeight: 700,
		fontSize: '20px',
		wordBreak: 'break-word',
	} as CSSProperties,
};
