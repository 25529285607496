import { AxiosInstance } from 'axios';
import { IUser } from '../interfaces';

export interface ITwitterRoutes {
	getTwitterRequestToken: (redirectPath?: string) => Promise<string>;
	postTwitterAccessToken: (
		oauth_token?: string,
		oauth_verifier?: string
	) => Promise<void>;
	linkTwitterAccount: (
		oauth_token: string,
		userId: string
	) => Promise<{
		doesUserAlreadyExist: boolean;
		twitterUsername?: string;
	}>;
	unlinkTwitterAccount: (userId?: string) => Promise<IUser>;
	manuallyLinkAccount: (twitterUsername: string) => Promise<IUser>;
}

export function getTwitterRoutes(client: AxiosInstance): ITwitterRoutes {
	const getTwitterRequestToken: (redirectPath?: string) => Promise<string> = (
		redirectPath?: string
	) =>
		client
			.get('/twitter/oauth/requestToken', {
				params: {
					redirectUrl: redirectPath
						? `${window.location.origin}${redirectPath}`
						: undefined,
				},
			})
			.then((res) => res.data.oauth_token);

	const postTwitterAccessToken: (
		oauth_token?: string,
		oauth_verifier?: string
	) => Promise<void> = (oauth_token, oauth_verifier) =>
		client.post('/twitter/oauth/accessToken', {
			oauth_token,
			oauth_verifier,
		});

	const linkTwitterAccount: (
		oauth_token?: string,
		userId?: string
	) => Promise<{
		doesUserAlreadyExist: boolean;
		twitterUsername?: string;
	}> = (oauth_token, userId) =>
		client
			.post('/twitter/linkAccount', { oauth_token, userId })
			.then((res) => {
				if (!res.data.doesUserAlreadyExist) {
					return {
						doesUserAlreadyExist: false,
						twitterUsername: res.data.screen_name,
					};
				} else {
					return {
						doesUserAlreadyExist: true,
					};
				}
			});

	const unlinkTwitterAccount: (userId?: string) => Promise<IUser> = (
		userId
	) =>
		client.post(`/twitter/unlinkAccount/${userId}`).then((res) => res.data);

	const manuallyLinkAccount: (twitterUsername: string) => Promise<IUser> = (
		twitterUsername
	) =>
		client
			.post(`/twitter/manuallyLinkAccount`, { twitterUsername })
			.then((res) => res.data);

	return {
		getTwitterRequestToken,
		postTwitterAccessToken,
		linkTwitterAccount,
		unlinkTwitterAccount,
		manuallyLinkAccount,
	};
}
