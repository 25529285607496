import { AxiosInstance } from 'axios';
import { ICampaign, IReferral, IUser } from '../interfaces';
import {
	IRefLink,
	IReferralData,
	IReferralInfo,
} from '../pages/Campaign/types';

export interface IReferralRoutes {
	getReferralInfo: (seed: string) => Promise<{
		referral: IReferral;
		user: IUser;
		campaign: ICampaign;
		redirectLink: string;
		tradingPair: string;
	}>;
	acceptReferral: (
		seed: string,
		referredAddress: string,
		signature: { challenge: string; signature: string; createdAt: number }
	) => Promise<{ redirectLink: string; referral: IReferral }>;
	getReferees: (
		campaignId: string,
		referrerId: string
	) => Promise<IReferralData[]>;
	getRefLinks: (
		campaignId: string,
		referrerId: string
	) => Promise<IRefLink[]>;
	createRefLink: (
		campaignId: string,
		referrerId: string,
		slug: string
	) => Promise<IRefLink>;
	deleteRefLink: (refLinkId: string) => Promise<IRefLink>;
	// Network Affiliate Campaign Referral routes:
	joinNetworkCampaign: (
		campaignId: string,
		campaignAccessCode: string,
		isReceivingEmails: boolean
	) => Promise<IReferral>;
	getMyNetworkCampaignAccessCode: (campaignId: string) => Promise<string>;
	getNetworkExternalCode: (
		campaignId: string
	) => Promise<{ btag?: string; linkUrl?: string }>;
	getCampaignAccessCodeReferral: (
		campaignId: string,
		campaignAccessCode: string
	) => Promise<{ user: IUser; campaign: ICampaign; referral: IReferral }>;
}

export function getReferralRoutes(client: AxiosInstance): IReferralRoutes {
	const getReferralInfo: (seed: string) => Promise<IReferralInfo> = (
		seed: string
	) => client.get(`/referrals/${seed}`).then((res) => res.data);

	const acceptReferral: (
		seed: string,
		referredAddress: string,
		signature: { challenge: string; signature: string; createdAt: number }
	) => Promise<{ redirectLink: string; referral: IReferral }> = (
		seed,
		referredAddress,
		signature
	) =>
		client
			.post(`/referrals/acceptReferral/${seed}`, {
				publicAddress: referredAddress,
				signature,
			})
			.then((res) => res.data);

	const getReferees: (
		campaignId: string,
		referrerId: string
	) => Promise<IReferralData[]> = (campaignId: string, referrerId: string) =>
		client
			.get(`/referrals/referees/${campaignId}/${referrerId}`)
			.then((res) => res.data);

	const getRefLinks: (
		campaignId: string,
		referrerId: string
	) => Promise<IRefLink[]> = (campaignId: string, referrerId: string) =>
		client
			.get(`/referrals/refLinks/${campaignId}/${referrerId}`)
			.then((res) => res.data);

	const createRefLink: (
		campaignId: string,
		referrerId: string,
		slug: string
	) => Promise<IRefLink> = (campaignId, referrerId, slug) =>
		client
			.post(`/referrals/createRefLink`, {
				campaignId,
				referrerId,
				slug,
			})
			.then((res) => res.data);

	const deleteRefLink: (refLinkId: string) => Promise<IRefLink> = (
		refLinkId: string
	) =>
		client
			.delete(`/referrals/deleteRefLink/${refLinkId}`)
			.then((res) => res.data);

	/*
		Network Affiliate Campaign Routes:
	*/

	const joinNetworkCampaign: (
		campaignId: string,
		campaignAccessCode: string,
		isReceivingEmails: boolean
	) => Promise<IReferral> = (
		campaignId,
		campaignAccessCode,
		isReceivingEmails
	) =>
		client
			.post(
				`/referrals/joinNetworkCampaign/${campaignId}/${campaignAccessCode}`,
				{
					campaignId,
					campaignAccessCode,
					isReceivingEmails,
				}
			)
			.then((res) => res.data);

	const getMyNetworkCampaignAccessCode: (
		campaignId: string
	) => Promise<string> = (campaignId: string) =>
		client
			.get(`/referrals/getMyNetworkCampaignAccessCode/${campaignId}`)
			.then((res) => res.data);

	const getNetworkExternalCode: (
		campaignId: string
	) => Promise<{ btag?: string; linkUrl?: string }> = (campaignId: string) =>
		client
			.get(`/referrals/getNetworkExternalCode/${campaignId}`)
			.then((res) => res.data);

	const getCampaignAccessCodeReferral: (
		campaignId: string,
		campaignAccessCode: string
	) => Promise<{ user: IUser; campaign: ICampaign; referral: IReferral }> = (
		campaignId: string,
		campaignAccessCode: string
	) =>
		client
			.get(`/referrals/getCampaignAccessCodeReferral/${campaignId}`, {
				params: { campaignAccessCode },
			})
			.then((res) => res.data);

	return {
		getReferralInfo,
		acceptReferral,
		getReferees,
		getRefLinks,
		createRefLink,
		deleteRefLink,
		joinNetworkCampaign,
		getMyNetworkCampaignAccessCode,
		getNetworkExternalCode,
		getCampaignAccessCodeReferral,
	};
}
