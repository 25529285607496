import { UserInfo } from '@particle-network/auth';

import { IWeb3Client } from '../../web3/web3Client';

export const signInUser = async (
	publicAddress: string,
	userInfo: UserInfo | null,
	web3Client: IWeb3Client,
	nonce?: string
): Promise<string | undefined> => {
	return fetch(`${process.env.REACT_APP_BACKEND_URL}/users/validateUser`, {
		body: JSON.stringify({ publicAddress }),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		credentials: 'include',
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			const { validatedUser, token } = data;
			if (validatedUser && token) {
				return token;
			} else {
				if (userInfo) {
					return getTokenForSocial(publicAddress, userInfo);
				} else {
					return getTokenForWallet(publicAddress, web3Client, nonce);
				}
			}
		})
		.catch((e) => {
			console.log(e);
			return;
		});
};

const getTokenForSocial = (publicAddress: string, userInfo: UserInfo) => {
	return fetch(
		`${process.env.REACT_APP_BACKEND_URL}/users/validateParticleToken?publicAddress=${publicAddress}&token=${userInfo.token}&userUuid=${userInfo.uuid}`
	)
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.loginSuccessful) {
				return data.token;
			} else {
				throw Error(data.error);
			}
		});
};

const getTokenForWallet = (
	publicAddress: string,
	web3Client: IWeb3Client,
	nonce?: string
) => {
	if (!nonce) {
		throw Error(`User nonce not found, nonce: ${nonce}`);
	}
	const message = `I am signing my one-time nonce: ${nonce}`
	return web3Client
		.signMessage(message)
		.then((signature) => {
			return fetch(
				`${process.env.REACT_APP_BACKEND_URL}/users/validateSignedMessage`,
				{
					body: JSON.stringify({ publicAddress, signature }),
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
					credentials: 'include',
				}
			);
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.loginSuccessful) {
				return data.token;
			} else {
				throw Error(data.error);
			}
		});
};

export const createUser = (publicAddress: string) =>
	fetch(`${process.env.REACT_APP_BACKEND_URL}/users`, {
		body: JSON.stringify({ publicAddress, username: publicAddress }),
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
	})
		.then((response) => response.json())
		.then((data) => data.user);

export const fetchUser = (publicAddress: string) => {
	return fetch(
		`${process.env.REACT_APP_BACKEND_URL}/users?publicAddress=${publicAddress}`
	)
		.then((response) => response.json())
		.then((data) => data.user);
};
