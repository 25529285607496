import { AxiosInstance } from 'axios';
import { BlobServiceClient } from '@azure/storage-blob';

export interface IImagesRoutes {
	uploadImage: (
		imageUsageType: string,
		image: Blob,
		campaignId?: string
	) => Promise<void>;
}

export function getImagesRoutes(client: AxiosInstance): IImagesRoutes {
	const uploadImage: (
		imageUsageType: string,
		image: Blob,
		campaignId?: string
	) => Promise<void> = async (imageUsageType, image, campaignId) => {
		const data: { sas: string; blobName: string } = await client
			.get(`/images/sas`, {
				params: { imageUsageType, campaignId },
			})
			.then((res) => res.data);
		await uploadImageWithSAS(image, data.blobName, data.sas);
	};
	return {
		uploadImage,
	};
}

export async function uploadImageWithSAS(
	image: Blob,
	blobName: string,
	sas: string
) {
	const blobServiceClient = new BlobServiceClient(
		`https://${
			process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME ?? ''
		}.blob.core.windows.net?${sas}`
	);
	const containerClient = blobServiceClient.getContainerClient(
		process.env.REACT_APP_AZURE_STORAGE_CONTAINER ?? ''
	);
	const blockBlobClient = containerClient.getBlockBlobClient(blobName);
	blockBlobClient
		.uploadData(image, {
			blobHTTPHeaders: {
				blobContentType: image.type,
			},
		})
		.catch((e) => {
			console.log(e);
		});
}
