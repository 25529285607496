import { AxiosInstance } from 'axios';
import { RawDraftContentState } from 'draft-js';
import {
	ICampaign,
	IComment,
	ICommunityHubUser,
	IReferral,
	ISubmittedTweet,
	IUser,
	IUserAllocations,
	TokenInfo,
} from '../interfaces';
import {
	IAffiliateData,
	ICampaignLeaderboardData,
	ICommunityHubMetrics,
	IEpochBalance,
	IGateTokenForm,
	IScoringRule,
	ISignatureResponse,
	IUserAffiliateData,
	Tweet,
} from '../pages/Campaign/types';
import { IAllocation, IMultiProof } from '../web3/web3Utils';
import { getImagesRoutes, uploadImageWithSAS } from './imagesRoutes';
import { imageUsageTypes } from '../utils/imageUtils';
import { generateMultiTokenGate } from '../utils/campaignUtils';

export interface ICampaignsRoutes {
	getCampaignById: (campaignId: string) => Promise<ICampaign>;
	getCampaigns: (
		createdByUserId?: string,
		currentCampaignId?: string,
		status?: string
	) => Promise<ICampaign[]>;
	getParticipantCampaigns(userId: string): Promise<ICampaign[]>;
	getCommunityHubs: (limit: number) => Promise<ICommunityHubUser[]>;
	getCommunityHubMetrics: (
		creatorId: string,
		campaignIds: string[]
	) => Promise<ICommunityHubMetrics>;
	getCampaignLeaderboard: (
		limit: number
	) => Promise<ICampaignLeaderboardData[]>;
	getReferrals: (campaignId: string) => Promise<IAffiliateData>;
	getUserAffiliateData: (userId: string) => Promise<IUserAffiliateData>;
	getSimulatedReferrals: (campaignId: string) => Promise<IAffiliateData>;
	getTotalAllocatedFunds: (contractAddress: string) => Promise<{
		totalAllocatedFunds: { [token: string]: string };
		removedFunds: { token: string; amount: string }[];
		claimedFunds: { token: string; amount: string }[];
	}>;
	updateRealtimeContainer: (
		containerId: string,
		campaignId: string
	) => Promise<void>;
	approveUser: (
		campaignId: string,
		userId: string,
		approvedUserId: string
	) => Promise<void>;
	removeUser: (
		campaignId: string,
		userId: string,
		removedUserId: string
	) => Promise<void>;
	acceptReward: (
		campaignId: string,
		userId: string,
		epochIds: string[],
		mappedEpochIds: number[]
	) => Promise<void>;
	acceptPPTRewards: (userId: string, userAddress: string) => Promise<void>;
	addEpoch: (
		campaignId: string,
		epochBalances: IEpochBalance[]
	) => Promise<ICampaign>;
	updateEpoch: (
		campaignId: string,
		epochBalances: IEpochBalance[],
		epochId: string
	) => Promise<ICampaign>;
	addFunds: (campaignId: string, funds: IAllocation[]) => Promise<ICampaign>;
	removeFunds: (
		campaignId: string,
		funds: IAllocation,
		txHash: string
	) => Promise<ICampaign>;
	claimPPT: (
		userId: string,
		userAddress: string
	) => Promise<ISignatureResponse>;
	getClaimRewardsInfo: (
		campaignId: string,
		userId: string,
		userAddress: string
	) => Promise<
		{
			allocations: IAllocation[];
			multiProof: IMultiProof;
			scaledScore: number;
			isClaimed: boolean;
			contractEpochId: number | undefined;
		}[]
	>;
	getSimulatedRewardsInfo: (
		campaignId: string,
		numParticipants: number
	) => Promise<{ [token: string]: { [id: string]: string } }>;
	userAdmission: (
		userAddress: string,
		approvedUserId: string,
		campaignId: string
	) => Promise<IReferral>;
	join: (
		campaignId: string,
		userId: string,
		userAddress: string,
		isReceivingEmails: boolean
	) => Promise<IReferral>;
	submitTweet: (
		campaignId: string,
		url: string
	) => Promise<{ submittedTweets: ISubmittedTweet[] }>;
	updateContractAddress: (
		campaignId: string,
		contractAddress: string,
		hasAddedFunds: boolean
	) => Promise<void>;
	simulateEpochAllocations: (campaignId: string) => Promise<{
		simulatedAllocations: IUserAllocations;
		affiliateData: IAffiliateData;
	}>;
	generateEpochAllocations: (campaignId: string) => Promise<{
		campaign: ICampaign;
	}>;
	generateAffiliateEpochAllocations: (campaignId: string) => Promise<{
		campaign: ICampaign;
	}>;
	uploadAllocations: (
		campaignId: string,
		epochId?: string,
		campaignEpoch?: number
	) => Promise<ICampaign | undefined>;
	createCampaign: (newCampaign: {
		goal: string;
		name: string;
		user: IUser;
		twitterUsername: string;
		useTwitterImage: boolean;
		pageLink: string;
		summary: string;
		description: string;
		type: string;
		gateType: string;
		isAccessCodeRequired?: boolean;
		conversionStyle: string;
		isSecret: boolean;
		searchTags: string[];
		logoFile?: Blob;
		bannerFile?: Blob;
		richSummary?: RawDraftContentState;
		richDescription?: RawDraftContentState;
		bioTag?: string;
		epochs?: string;
		startAt?: number | undefined;
		chain?: number;
		campaignBalancesByEpoch?: {
			[epoch: string]: IEpochBalance[];
		};
		funds: { token: string; amount: string }[];
		tokenInfos: { [token: string]: TokenInfo };
		customConversion?: {
			position: number;
			percentage: number;
		}[];
		cpmBid?: string;
		scoringRules?: IScoringRule[];
		multipleGateTokens?: IGateTokenForm[];
		affiliateType?: string;
		affiliateContractType?: string;
		tradingPairContract?: {
			address: string;
			chainId: number;
			rewardToken: string;
		};
		affiliateProgramInfo?: ICampaign['affiliateProgramInfo'];
		affiliateRedirectLink?: string;
		campaignRoles?: {
			[role: string]: string[]; // role: "manager" | "etc" => publicAddress[]
		};
	}) => Promise<ICampaign>;
	updateCampaign: (campaign: {
		id: string;
		name: string;
		user: IUser;
		twitterUsername: string;
		lensUsername?: string;
		pageLink: string;
		summary: string;
		description: string;
		searchTags: string[];
		logoFile?: Blob;
		bannerFile?: Blob;
		richSummary?: RawDraftContentState;
		richDescription?: RawDraftContentState;
		bioTag?: string;
		startAt?: number | undefined;
		conversionStyle?: string;
		customConversion?: {
			position: number;
			percentage: number;
		}[];
		gateType: string;
		isAccessCodeRequired?: boolean;
		isSecret?: boolean;
		affiliateProgramInfo?: ICampaign['affiliateProgramInfo'];
		multipleGateTokens?: IGateTokenForm[];
		campaignRoles?: {
			[role: string]: string[]; // role: "manager" | "etc" => publicAddress[]
		};
	}) => Promise<ICampaign>;
	deleteCampaign: (campaignId: string) => Promise<void>;
	updateTweetScore: (params: {
		campaignId: string;
		isCampaignTweet: boolean;
		tweetId: string;
		score: number;
		tweetAuthorId: string;
		currentUserId: string;
	}) => Promise<{ [userId: string]: number }>;
	scoreTweet: (params: {
		campaignId: string;
		tweetId: string;
		score?: number;
		tweetType?: string;
		isAccepted?: boolean;
	}) => Promise<ISubmittedTweet>;
	rescoreTweet: (params: {
		campaignId: string;
		tweetId: string;
		score?: number;
		tweetType?: string;
		isAccepted?: boolean;
	}) => Promise<ISubmittedTweet>;
	updateTweetComments: (params: {
		campaignId: string;
		isCampaignTweet: boolean;
		tweetId: string;
		comments: IComment[];
		tweetAuthorId: string;
		currentUserId: string;
	}) => Promise<IComment[]>;
	incrementReferral: (seed: string) => Promise<IReferral>;
	getCampaignTweets: (campaignId: string) => Promise<{
		tweets: { [tweetId: string]: Tweet };
		submittedTweets: ISubmittedTweet[];
	}>;
	getRemovedFunds: (
		contractAddress: string
	) => Promise<{ token: string; amount: string }[] | undefined>;
	getDrafts: () => Promise<ICampaign[]>; // get all drafts, for admins only
	getMyDrafts: () => Promise<ICampaign[]>; // gets drafts for user making the request
	deleteDraft: (
		campaignId: string
	) => Promise<{ deletedCampaign: ICampaign }>;
	createDraft: (values: any) => Promise<ICampaign>; // to-do: type values (perhaps use zod schemas)
	patchDraft: (campaignId: string, values: any) => Promise<ICampaign>; // to-do: type values
	launchDraft: (campaignId: string) => Promise<ICampaign>;
}

export function getCampaignsRoutes(client: AxiosInstance): ICampaignsRoutes {
	const getCampaigns: (
		createdByUserId?: string,
		currentCampaignId?: string,
		status?: string,
		page?: number,
		limit?: number
	) => Promise<ICampaign[]> = (
		createdByUserId,
		currentCampaignId,
		status,
		page,
		limit
	) =>
		client
			.get(`/campaigns`, {
				params: {
					createdByUserId,
					currentCampaignId,
					status,
					page,
					limit,
				},
			})
			.then((res) => res.data);

	const getParticipantCampaigns: (userId: string) => Promise<ICampaign[]> = (
		userId
	) =>
		client
			.get(`/campaigns/getParticipantCampaigns/${userId}`, {
				params: {
					short: true,
				},
			})
			.then((res) => res.data);

	const getCampaignLeaderboard: (
		limit: number
	) => Promise<ICampaignLeaderboardData[]> = (limit) =>
		client
			.get(`/campaigns/campaignLeaderboard/${limit}`)
			.then((res) => res.data);

	const getCommunityHubs: (limit: number) => Promise<ICommunityHubUser[]> = (
		limit
	) =>
		client.get(`/campaigns/communityHubs/${limit}`).then((res) => res.data);

	const getCommunityHubMetrics: (
		creatorId: string,
		campaignIds: string[]
	) => Promise<ICommunityHubMetrics> = (creatorId, campaignIds) =>
		client
			.post(`/campaigns/getMetricsFor/${creatorId}`, { campaignIds })
			.then((res) => res.data);

	const getCampaignTweets: (campaignId: string) => Promise<{
		tweets: { [tweetId: string]: Tweet };
		submittedTweets: ISubmittedTweet[];
	}> = (campaignId) =>
		client
			.get(`/campaigns/campaignTweets/${campaignId}`)
			.then((res) => res.data);

	const updateRealtimeContainer: (
		containerId: string,
		campaignId: string
	) => Promise<void> = (containerId, campaignId) =>
		client.post('/campaigns/updateRealtimeContainer', {
			realtimeContainerId: containerId,
			campaignId: campaignId,
		});

	const getCampaignById: (campaignId: string) => Promise<ICampaign> = (
		campaignId
	) => client.get(`/campaigns/${campaignId}`).then((res) => res.data);

	const getReferrals: (campaignId: string) => Promise<IAffiliateData> = (
		campaignId
	) =>
		client
			.get(`/campaigns/referrals/${campaignId}`)
			.then((res) => res.data);

	const getUserAffiliateData: (
		userId: string
	) => Promise<IUserAffiliateData> = (userId) =>
		client
			.get(`/campaigns/userAffiliateData/${userId}`)
			.then((res) => res.data);

	const getSimulatedReferrals: (
		campaignId: string
	) => Promise<IAffiliateData> = (campaignId) =>
		client
			.get(`/campaigns/simulated/referrals/${campaignId}`)
			.then((res) => res.data);

	const getTotalAllocatedFunds: (contractAddress: string) => Promise<{
		totalAllocatedFunds: { [token: string]: string };
		removedFunds: { token: string; amount: string }[];
		claimedFunds: { token: string; amount: string }[];
	}> = (contractAddress) =>
		client
			.get(`/campaigns/getTotalAllocatedFunds/${contractAddress}`)
			.then((res) => res.data);

	const approveUser: (
		campaignId: string,
		userId: string,
		approvedUserId: string
	) => Promise<void> = (campaignId, userId, approvedUserId) =>
		client
			.post('/campaigns/approveUser', {
				userId,
				approvedUserId,
				campaignId,
			})
			.then((res) => res.data);

	const removeUser: (
		campaignId: string,
		userId: string,
		removedUserId: string
	) => Promise<void> = (campaignId, userId, removedUserId) =>
		client
			.post('/campaigns/removeUser', {
				userId,
				removedUserId,
				campaignId,
			})
			.then((res) => res.data);

	const acceptReward: (
		campaignId: string,
		userId: string,
		epochIds: string[],
		userClaimsEpochs: number[]
	) => Promise<void> = (campaignId, userId, epochIds, userClaimsEpochs) =>
		client
			.post(`/campaigns/acceptReward/${campaignId}`, {
				userId,
				epochIds,
				userClaimsEpochs,
			})
			.then((res) => res.data);

	const acceptPPTRewards: (
		userId: string,
		userAddress: string
	) => Promise<void> = (userId, userAddress) =>
		client
			.post(`/campaigns/acceptPPTRewards`, {
				userId,
				userAddress,
			})
			.then((res) => res.data);

	const getSimulatedRewardsInfo: (
		campaignId: string,
		numParticipants: number
	) => Promise<{ [token: string]: { [id: string]: string } }> = (
		campaignId,
		numParticipants
	) =>
		client
			.get(`/campaigns/simulateEpochAllocations/${campaignId}`, {
				params: { numParticipants },
			})
			.then((res) => res.data);

	const userAdmission: (
		userAddress: string,
		approvedUserId: string,
		campaignId: string
	) => Promise<IReferral> = (userAddress, approvedUserId, campaignId) =>
		client
			.post(`/campaigns/userAdmission`, {
				userAddress,
				approvedUserId,
				campaignId,
			})
			.then((res) => res.data);

	const addEpoch: (
		campaignId: string,
		epochBalances: IEpochBalance[]
	) => Promise<ICampaign> = (campaignId, epochBalances) =>
		client
			.post(`/campaigns/addEpoch/${campaignId}`, { epochBalances })
			.then((res) => res.data);

	const updateEpoch: (
		campaignId: string,
		epochBalances: IEpochBalance[],
		epochId: string
	) => Promise<ICampaign> = (campaignId, epochBalances, epochId) =>
		client
			.post(`/campaigns/updateEpoch/${campaignId}`, {
				epochBalances,
				epochId,
			})
			.then((res) => res.data);

	const addFunds: (
		campaignId: string,
		funds: IAllocation[]
	) => Promise<ICampaign> = (campaignId, funds) =>
		client
			.post(`/campaigns/addFunds/${campaignId}`, { funds })
			.then((res) => res.data);

	const removeFunds: (
		campaignId: string,
		funds: IAllocation,
		txHash: string
	) => Promise<ICampaign> = (campaignId, funds, txHash) =>
		client
			.post(`/campaigns/removeFunds/${campaignId}`, { funds, txHash })
			.then((res) => res.data);

	const claimPPT: (
		userId: string,
		userAddress: string
	) => Promise<ISignatureResponse> = (userId, userAddress) =>
		client
			.get(`/campaigns/claimPPT/${userId}/${userAddress}`)
			.then((res) => res.data);

	const getClaimRewardsInfo: (
		campaignId: string,
		userId: string,
		userAddress: string
	) => Promise<
		{
			allocations: IAllocation[];
			multiProof: IMultiProof;
			scaledScore: number;
			isClaimed: boolean;
			contractEpochId: number | undefined;
		}[]
	> = (campaignId, userId, userAddress) =>
		client
			.get(
				`/campaigns/getClaimRewardsInfo/${campaignId}/${userId}/${userAddress}`
			)
			.then((res) => res.data);

	const join: (
		campaignId: string,
		userId: string,
		userAddress: string,
		isReceivingEmails: boolean
	) => Promise<IReferral> = (
		campaignId,
		userId,
		userAddress,
		isReceivingEmails
	) =>
		client
			.post(`/campaigns/join/${campaignId}`, {
				campaignId,
				userId,
				userAddress,
				isReceivingEmails,
			})
			.then((res) => res.data);

	const submitTweet: (
		campaignId: string,
		url: string
	) => Promise<{ submittedTweets: ISubmittedTweet[] }> = (campaignId, url) =>
		client
			.post(`/campaigns/submitTweet`, {
				campaignId,
				url,
			})
			.then((res) => res.data);

	const updateContractAddress: (
		campaignId: string,
		contractAddress: string,
		hasAddedFunds: boolean
	) => Promise<void> = (campaignId, contractAddress, hasAddedFunds) =>
		client
			.post(`/campaigns/updateContractAddress`, {
				campaignId,
				contractAddress,
				hasAddedFunds,
			})
			.then((res) => res.data);

	const simulateEpochAllocations: (campaignId: string) => Promise<{
		simulatedAllocations: IUserAllocations;
		affiliateData: IAffiliateData;
	}> = (campaignId) =>
		client
			.get(`/campaigns/simulateAllocations/${campaignId}`)
			.then((res) => res.data);

	const generateEpochAllocations: (campaignId: string) => Promise<{
		campaign: ICampaign;
	}> = (campaignId) =>
		client
			.get(`/campaigns/generateEpochAllocations/${campaignId}`)
			.then((res) => res.data);

	const generateAffiliateEpochAllocations: (campaignId: string) => Promise<{
		campaign: ICampaign;
	}> = (campaignId) =>
		client
			.get(`/campaigns/generateAffiliateEpochAllocations/${campaignId}`)
			.then((res) => res.data);

	const uploadAllocations: (
		campaignId: string,
		epochId?: string,
		campaignEpoch?: number
	) => Promise<ICampaign | undefined> = (
		campaignId,
		epochId,
		campaignEpoch
	) =>
		client
			.post(`/campaigns/uploadAllocations/${campaignId}`, {
				epochId,
				campaignEpoch,
			})
			.then((res) => res.data);

	const createCampaign: (newCampaign: {
		goal: string;
		name: string;
		user: IUser;
		twitterUsername: string;
		useTwitterImage: boolean;
		pageLink: string;
		summary: string;
		description: string;
		type: string;
		gateType: string;
		isAccessCodeRequired?: boolean;
		conversionStyle: string;
		isSecret: boolean;
		searchTags: string[];
		logoFile?: Blob;
		bannerFile?: Blob;
		richSummary?: RawDraftContentState;
		richDescription?: RawDraftContentState;
		bioTag?: string;
		epochs?: string;
		startAt?: number | undefined;
		chain?: number;
		campaignBalancesByEpoch?: {
			[epoch: string]: IEpochBalance[];
		};
		funds: { token: string; amount: string }[];
		tokenInfos: { [token: string]: TokenInfo };
		customConversion?: {
			position: number;
			percentage: number;
		}[];
		cpmBid?: string;
		scoringRules?: IScoringRule[];
		multipleGateTokens?: IGateTokenForm[];
		affiliateType?: string;
		affiliateContractType?: string;
		tradingPairContract?: {
			address: string;
			chainId: number;
			rewardToken: string;
		};
		affiliateProgramInfo?: ICampaign['affiliateProgramInfo'];
		affiliateRedirectLink?: string;
		campaignRoles?: {
			[role: string]: string[]; // role: "manager" | "etc" => publicAddress[]
		};
	}) => Promise<ICampaign> = (newCampaign) => {
		const {
			goal,
			name,
			user,
			twitterUsername,
			useTwitterImage,
			pageLink,
			summary,
			description,
			type,
			conversionStyle,
			gateType,
			isAccessCodeRequired,
			isSecret,
			searchTags,
			logoFile,
			bannerFile,
			richSummary,
			richDescription,
			bioTag,
			epochs,
			startAt,
			chain,
			campaignBalancesByEpoch,
			funds,
			tokenInfos,
			customConversion,
			cpmBid,
			scoringRules,
			multipleGateTokens,
			affiliateType,
			affiliateContractType,
			tradingPairContract,
			affiliateProgramInfo,
			affiliateRedirectLink,
			campaignRoles,
		} = newCampaign;
		const data = new FormData();
		const currentTimeInMs = Date.now();
		// if (logoFile) {
		// 	data.append('logoFile', logoFile);
		// }
		// if (bannerFile) {
		// 	data.append('bannerFile', bannerFile);
		// }
		data.append('goal', goal);
		data.append('name', name);
		if (twitterUsername && twitterUsername[0] === '@') {
			data.append('twitterUsername', twitterUsername.substring(1));
		} else {
			data.append('twitterUsername', twitterUsername);
		}
		data.append('twitterId', user?.twitterId);
		data.append('useTwitterImage', `${useTwitterImage}`);
		data.append('pageLink', pageLink);
		data.append('summary', summary);
		data.append('description', description);
		data.append('createdAt', `${currentTimeInMs}`);
		data.append('updatedAt', `${currentTimeInMs}`);
		data.append('createdByUserId', `${user.id}`);
		data.append('type', type);
		data.append('gateType', gateType);
		data.append('conversionStyle', conversionStyle);
		data.append('isSecret', `${isSecret}`);
		data.append('searchTags', searchTags.join('-TAG-DELIMITER-'));
		data.append(
			'campaignBalancesByEpoch',
			JSON.stringify(campaignBalancesByEpoch)
		);
		data.append('funds', JSON.stringify(funds));
		data.append('tokenInfos', JSON.stringify(tokenInfos));
		if (cpmBid) {
			data.append('cpmBid', cpmBid);
		}
		if (scoringRules) {
			data.append('scoringRules', JSON.stringify(scoringRules));
		}
		if (customConversion) {
			data.append('customConversion', JSON.stringify(customConversion));
		}
		if (bioTag) {
			data.append('bioTag', bioTag);
		}
		if (epochs) {
			data.append('epochs', epochs);
		}
		if (startAt) {
			data.append('startAt', `${startAt}`);
		} else {
			data.append('startAt', `${currentTimeInMs}`);
		}
		if (chain) {
			data.append('chain', `${chain}`);
		}
		if (richSummary) {
			data.append('richSummary', JSON.stringify(richSummary));
		}
		if (richDescription) {
			data.append('richDescription', JSON.stringify(richDescription));
		}
		if (multipleGateTokens) {
			data.append(
				'multiTokenGate',
				JSON.stringify(generateMultiTokenGate(multipleGateTokens))
			);
		}
		if (type === 'affiliate') {
			if (affiliateType) {
				data.append('affiliateType', affiliateType);
			}
			if (affiliateType === 'smartContract') {
				if (affiliateContractType) {
					data.append('affiliateContractType', affiliateContractType);
				}
				if (tradingPairContract) {
					data.append(
						'tradingPairContract',
						JSON.stringify(tradingPairContract)
					);
				}
			}
			if (affiliateRedirectLink) {
				data.append('affiliateRedirectLink', affiliateRedirectLink);
			}
			if (affiliateType === 'network') {
				data.append('isAccessCodeRequired', `${isAccessCodeRequired}`);
				if (affiliateProgramInfo) {
					data.append(
						'affiliateProgramInfo',
						JSON.stringify(affiliateProgramInfo)
					);
				}
			}
		}
		if (campaignRoles) {
			data.append('campaignRoles', JSON.stringify(campaignRoles));
		}
		if (user.twitterProfileImageUrl) {
			data.append(
				'twitterProfileImageUrl',
				`${user.twitterProfileImageUrl}`
			);
		}
		return client.post('/campaigns', data).then(async (res) => {
			if (logoFile) {
				await uploadImageWithSAS(
					logoFile,
					res.data.logoUrlPath,
					res.data.sas.logo
				);
			}
			if (bannerFile) {
				await uploadImageWithSAS(
					bannerFile,
					res.data.bannerUrlPath,
					res.data.sas.banner
				);
			}
			return res.data;
		});
	};

	const updateCampaign: (campaign: {
		id: string;
		name: string;
		user: IUser;
		twitterUsername: string;
		lensUsername?: string;
		pageLink: string;
		summary: string;
		description: string;
		searchTags: string[];
		logoFile?: Blob;
		bannerFile?: Blob;
		richSummary?: RawDraftContentState;
		richDescription?: RawDraftContentState;
		bioTag?: string;
		startAt?: number | undefined;
		conversionStyle?: string;
		customConversion?: {
			position: number;
			percentage: number;
		}[];
		gateType: string;
		isAccessCodeRequired?: boolean;
		isSecret?: boolean;
		affiliateProgramInfo?: ICampaign['affiliateProgramInfo'];
		multipleGateTokens?: IGateTokenForm[];
		campaignRoles?: {
			[role: string]: string[]; // role: "manager" | "etc" => publicAddress[]
		};
	}) => Promise<ICampaign> = (campaign) => {
		const {
			id,
			name,
			user,
			twitterUsername,
			lensUsername,
			pageLink,
			summary,
			description,
			searchTags,
			logoFile,
			bannerFile,
			richSummary,
			richDescription,
			bioTag,
			startAt,
			conversionStyle,
			customConversion,
			gateType,
			isAccessCodeRequired,
			isSecret,
			affiliateProgramInfo,
			multipleGateTokens,
			campaignRoles,
		} = campaign;
		const data = new FormData();
		data.append('name', name);
		if (twitterUsername && twitterUsername[0] === '@') {
			data.append('twitterUsername', twitterUsername.substring(1));
		} else {
			data.append('twitterUsername', twitterUsername);
		}
		if (lensUsername) {
			if (lensUsername[0] === '@') {
				data.append('lensUsername', lensUsername.substring(1));
			} else {
				data.append('lensUsername', lensUsername);
			}
		}
		data.append('twitterProfileImageUrl', `${user.twitterProfileImageUrl}`);
		data.append('pageLink', pageLink);
		if (summary) {
			data.append('summary', summary);
		}
		if (description) {
			data.append('description', description);
		}
		data.append('updatedAt', `${Date.now()}`);
		data.append('searchTags', searchTags.join('-TAG-DELIMITER-'));
		if (richSummary) {
			data.append('richSummary', JSON.stringify(richSummary));
		}
		if (richDescription) {
			data.append('richDescription', JSON.stringify(richDescription));
		}
		if (multipleGateTokens) {
			data.append(
				'multiTokenGate',
				JSON.stringify(generateMultiTokenGate(multipleGateTokens))
			);
		}
		if (bioTag) {
			data.append('bioTag', bioTag);
		}
		if (startAt) {
			data.append('startAt', `${startAt}`);
		}
		if (conversionStyle === 'custom') {
			data.append('customConversion', JSON.stringify(customConversion));
		}
		if (campaignRoles) {
			data.append('campaignRoles', JSON.stringify(campaignRoles));
		}
		data.append('gateType', gateType);
		if (isAccessCodeRequired !== undefined) {
			data.append('isAccessCodeRequired', `${isAccessCodeRequired}`);
		}
		data.append('isSecret', `${isSecret}`);

		if (affiliateProgramInfo) {
			data.append(
				'affiliateProgramInfo',
				JSON.stringify(affiliateProgramInfo)
			);
		}

		let uploadImagePromises = [];
		if (logoFile) {
			// data.append('logoFile', logoFile); // upload to backend
			uploadImagePromises.push(
				getImagesRoutes(client).uploadImage(
					imageUsageTypes.campaignLogo,
					logoFile,
					id
				)
			); // upload image to cdn
		}
		if (bannerFile) {
			// data.append('bannerFile', bannerFile); // upload to backend
			uploadImagePromises.push(
				getImagesRoutes(client).uploadImage(
					imageUsageTypes.campaignBanner,
					bannerFile,
					id
				)
			); // upload image to cdn
		}
		return Promise.all(uploadImagePromises)
			.then(() => new Promise((r) => setTimeout(r, 500))) // sleep
			.then(() => client.patch(`/campaigns/${id}`, data))
			.then((res) => res.data);
	};

	const deleteCampaign: (campaignId: string) => Promise<void> = (
		campaignId
	) => client.delete(`/campaigns/${campaignId}`).then((res) => res.data);

	const updateTweetScore: (params: {
		campaignId: string;
		isCampaignTweet: boolean;
		tweetId: string;
		score: number | undefined;
		isAccepted?: boolean;
		tweetAuthorId: string;
		currentUserId: string;
	}) => Promise<{ [userId: string]: number }> = (params) =>
		client
			.post('/campaigns/updateTweetScore', params)
			.then((res) => res.data);

	const scoreTweet: (params: {
		campaignId: string;
		tweetId: string;
		score?: number;
		tweetType?: string;
		isAccepted?: boolean;
	}) => Promise<ISubmittedTweet> = (params) =>
		client
			.post(`/campaigns/scoreTweet/${params.campaignId}`, params)
			.then((res) => res.data);

	const rescoreTweet: (params: {
		campaignId: string;
		tweetId: string;
		score?: number;
		tweetType?: string;
		isAccepted?: boolean;
	}) => Promise<ISubmittedTweet> = (params) =>
		client
			.post(`/campaigns/rescoreTweet/${params.campaignId}`, params)
			.then((res) => res.data);

	const updateTweetComments: (params: {
		campaignId: string;
		isCampaignTweet: boolean;
		tweetId: string;
		comments: IComment[];
		tweetAuthorId: string;
		currentUserId: string;
	}) => Promise<IComment[]> = (params) =>
		client
			.post('/campaigns/updateTweetComments', params)
			.then((res) => res.data);

	const incrementReferral: (seed: string) => Promise<IReferral> = (seed) =>
		client
			.get(`/campaigns/referrals/increment/${seed}`)
			.then((res) => res.data);

	const getRemovedFunds = (contractAddress: string) =>
		client
			.get(`/campaigns/removedFunds/${contractAddress}`)
			.then((res) => res.data);

	const getDrafts = () =>
		client.get('/campaigns/drafts/getDrafts').then((res) => res.data);

	const getMyDrafts = () =>
		client.get('/campaigns/drafts/getMyDrafts').then((res) => res.data);

	const deleteDraft = (campaignId: string) =>
		client
			.delete(`/campaigns/drafts/${campaignId}`)
			.then((res) => res.data);

	const createDraft = (
		values: any // to-do: type values
	) =>
		client.post('/campaigns/createDraft', values).then(async (res) => {
			if (values.logoFile) {
				await uploadImageWithSAS(
					values.logoFile,
					res.data.logoUrlPath,
					res.data.sas.logo
				);
			}
			if (values.bannerFile) {
				await uploadImageWithSAS(
					values.bannerFile,
					res.data.bannerUrlPath,
					res.data.sas.banner
				);
			}
			return res.data;
		});

	const patchDraft = (
		campaignId: string,
		values: any // to-do: type values
	) =>
		client
			.patch(`/campaigns/patchDraft/${campaignId}`, values)
			.then((res) => res.data);

	const launchDraft = (campaignId: string) =>
		client
			.post(`/campaigns/launchDraft/${campaignId}`)
			.then((res) => res.data);

	return {
		getCampaignById,
		getCampaigns,
		getParticipantCampaigns,
		getCommunityHubs,
		getCommunityHubMetrics,
		getCampaignLeaderboard,
		getReferrals,
		getUserAffiliateData,
		updateRealtimeContainer,
		getSimulatedReferrals,
		getTotalAllocatedFunds,
		approveUser,
		removeUser,
		acceptReward,
		acceptPPTRewards,
		addEpoch,
		updateEpoch,
		addFunds,
		removeFunds,
		claimPPT,
		getClaimRewardsInfo,
		getSimulatedRewardsInfo,
		userAdmission,
		join,
		submitTweet,
		updateContractAddress,
		simulateEpochAllocations,
		generateEpochAllocations,
		generateAffiliateEpochAllocations,
		uploadAllocations,
		createCampaign,
		updateCampaign,
		deleteCampaign,
		updateTweetScore,
		scoreTweet,
		rescoreTweet,
		updateTweetComments,
		incrementReferral,
		getCampaignTweets,
		getRemovedFunds,
		getDrafts,
		getMyDrafts,
		deleteDraft,
		createDraft,
		patchDraft,
		launchDraft,
	};
}
